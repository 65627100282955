
import { defineComponent } from 'vue'
import LoadingProgress from './components/LoadingProgress/LoadingProgress.vue'
import AppLogo from '@/components/AppHeader/AppLogo/AppLogo.vue'
import AppHeader from '@/components/AppHeader/AppHeader.vue'
import DevelopedBy from '@/components/DevelopedBy/DevelopedBy.vue'
import CesiumViewer from '@/components/CesiumViewer/CesiumViewer.vue'
import Races from '@/components/Races/Races.vue'
import ElevationProfile from '@/components/ElevationProfile/ElevationProfile.vue'
import ConfigManager from '@/shared/configManager'
import MappingManager from '@/shared/mappingManager'
import { IconStyle } from '@/models/config'
import EventsManager from '@/shared/eventsManager'
import ToolsManager from '@/shared/toolsManager'

export default defineComponent({
  name: 'App',
  components: {
    LoadingProgress, CesiumViewer, AppLogo, AppHeader, DevelopedBy, Races, ElevationProfile
  },
  data () {
    return {
      configLoaded: false,
      dataLoaded: false
    }
  },
  mounted: async function () {
    ToolsManager.getInstance().isMobileView = (this as any).$isMobile()
    EventsManager.getInstance().eventEmitter.emit('showLoading' as any, { visible: true })
    await ConfigManager.getInstance().loadConfig()
    this.setStylesIcons()
    this.configLoaded = true

    const currentVersion = ConfigManager.getInstance().config.version
    const storedVersion = localStorage.getItem('arxit-escalade-app-version')

    if (!storedVersion || currentVersion !== storedVersion) {
      localStorage.setItem('arxit-escalade-app-version', currentVersion)
      let url = window.location.href
      if (url.indexOf('?') !== -1) {
        url = url.split('?')[0]
      }

      window.location.href = `${url}?v=${currentVersion}`
    }

    await MappingManager.getInstance().loadData()
    MappingManager.getInstance().initViewerData()
    this.dataLoaded = true
  },
  methods: {
    getImage (iconPath: string): any {
      return require(`@/assets/${iconPath}`)
    },
    setStylesIcons (): void {
      // Renseignement icons
      ConfigManager.getInstance().config.data.coursesData.renseignements.style.forEach((i: IconStyle) => {
        MappingManager.getInstance().stylesIcons.renseignements[i.icon] = this.getImage(`styles/${i.icon}`)
      })

      // PK
      Array.from(Array(23), (_, i: number) => i + 1).forEach((i: number) => {
        MappingManager.getInstance().stylesIcons.pk[`PK_${i}`] = this.getImage(`icons/PK/PK_${i}.png`)
      })

      // Start & finish
      MappingManager.getInstance().stylesIcons.startFinish.start = this.getImage('icons/start.png')
      MappingManager.getInstance().stylesIcons.startFinish.finish = this.getImage('icons/finish.png')
      MappingManager.getInstance().stylesIcons.startFinish.startFinish = this.getImage('icons/startFinish.png')

      // Other icons
      MappingManager.getInstance().stylesIcons.icons.cross = this.getImage('icons/cross.png')
      MappingManager.getInstance().stylesIcons.icons.pushpin = this.getImage('icons/pushpin.png')
      MappingManager.getInstance().stylesIcons.icons.street = this.getImage('icons/street.png')
    }
  }
})
