
import { defineComponent, watch, computed, reactive } from 'vue'
import SideNav from '../../widgets/SideNav/SideNav.vue'
import { useStore } from 'vuex'
import { ElevProfileResult } from '@/models/courses'
import MappingManager from '@/shared/mappingManager'
import EventsManager from '@/shared/eventsManager'
import ConfigManager from '@/shared/configManager'
import ToolsManager from '@/shared/toolsManager'

export default defineComponent({
  name: 'ElevationProfile',
  components: {
    SideNav
  },
  data () {
    return {
      isMobileView: ToolsManager.getInstance().isMobileView,
      elevationProfile: null as any,
      visible: false,
      sideNavWidth: window.innerWidth - 20,
      progressValue: 0,
      progressMaxValue: 100,
      expanded: false,
      isPlaying: false,
      isUpdating: false,
      store: null as any,
      totalDistance: 0,
      traveledDistance: 0,
      positiveElevation: 0,
      negativeElevation: 0,
      distance: 0,
      travelTimes: [] as any[],
      showPlay: ConfigManager.getInstance().config.cesiumOptions.defaultMode === 3 &&
        ConfigManager.getInstance().config.cesiumOptions.enable3D &&
        !ToolsManager.getInstance().isMobileView,
      options: {
        chart: {
          foreColor: '#373d3f',
          id: '',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false
            }
          },
          zoom: {
            enabled: false
          }
        },
        grid: {
          show: false
        },
        tooltip: {
          x: {
            show: false
          },
          custom: (event: any) => {
            return this.handleTooltip(event)
          }
        },
        xaxis: {
          type: 'numeric',
          tickAmount: 'dataPoints',
          categories: [0],
          labels: {
            show: true,
            style: {
              colors: '#fff',
              fontSize: '0',
              cssClass: 'apexcharts-xaxis-label'
            }/* ,
            formatter: function (value: number, a: any, options: any) {
              let condition = value % 1000 === 0

              if (!condition && options && options.xaxis.categories && options.xaxis.categories.length) {
                condition = value === options.xaxis.categories[0] || value === options.xaxis.categories[options.xaxis.categories.length - 1]
              }
              return condition ? value : ''
            } */
          },
          tooltip: {
            enabled: false
          }
        },
        yaxis: {
          labels: {
            show: true,
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 800,
              cssClass: 'apexcharts-yaxis-label'
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          }
        },
        colors: [ConfigManager.getInstance().config.data.coursesData.style.color],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          lineCap: 'butt',
          width: 6,
          dashArray: 0
        },
        fill: {
          type: 'gradient',
          gradient: {
            opacityFrom: 0.1,
            opacityTo: 0.2
          }
        },
        series: [{
          name: 'Elévation',
          data: [0]
        }],
        annotations: {
          xaxis: [],
          points: []
        }
      }
    }
  },

  methods: {
    updateElevationProfile: function (elevProfile: ElevProfileResult) {
      this.elevationProfile = elevProfile

      // Handle positive and negative elevation
      this.handlePositiveNegativeElevation()

      this.isPlaying = false
      this.isUpdating = true
      const opt = { ...this.options }
      opt.xaxis.categories = this.elevationProfile.mValues
      this.options.series[0].data = this.elevationProfile.zValues
      const annotationXaxis: any[] = []
      const annotationPoints: any[] = []
      this.elevationProfile.mValues.forEach((m: number, i: number) => {
        if (m > 0 && m % 1000 === 0) {
          annotationXaxis.push(
            {
              x: m,
              strokeDashArray: 0,
              borderColor: '#000',
              label: {
                borderColor: '#fff',
                style: {
                  color: '#fff',
                  background: '#000'
                },
                text: `KM ${m / 1000}`
              }
            }
          )

          /* annotationPoints.push(
            {
              x: m,
              y: this.elevationProfile.zValues[i],
              marker: {
                size: 6,
                fillColor: '#fff',
                strokeColor: '#000',
                radius: 2
              }
            }
          ) */
        }
      })

      opt.annotations.xaxis = annotationXaxis as any
      opt.annotations.points = annotationPoints as any
      this.options = reactive(opt)

      this.totalDistance = this.elevationProfile.mValues[this.elevationProfile.mValues.length - 1]
      this.progressValue = 0
      this.visible = true

      /* setTimeout(() => {
        EventsManager.getInstance().eventEmitter.emit('openElevationProfile' as any)
      }, 500) */

      setTimeout(() => {
        this.isUpdating = false
      }, 1000)
    },
    updateTravelTimes: function (travelTimes: any[]) {
      this.travelTimes = travelTimes
    },
    handlePositiveNegativeElevation: function () {
      if (this.elevationProfile && this.elevationProfile.courseInfos) {
        this.positiveElevation = this.elevationProfile.courseInfos.properties.positiveElevation
        this.negativeElevation = this.elevationProfile.courseInfos.properties.negativeElevation
        this.distance = this.elevationProfile.courseInfos.properties.distance
      } else {
        this.positiveElevation = 0
        this.negativeElevation = 0
        this.distance = 0
      }
    },
    handleTooltip: function (event: any) {
      const elevProfile: ElevProfileResult = this.elevationProfile
      if (event.dataPointIndex >= 0 && elevProfile?.coordinates?.length > event.dataPointIndex) {
        MappingManager.getInstance().handleHoverCourseLine(elevProfile.coordinates[event.dataPointIndex])
      }
      const distance = this.elevationProfile?.mValues[event.dataPointIndex]

      return `
        <div class="customTooltipDiv">
          <span>Distance : <strong>${distance} m</strong></span>
          </br>
          <span>Elévation : <strong>${event.series[event.seriesIndex][event.dataPointIndex]} m</strong></span>
        </div>
      `
    },
    togglePlay: function () {
      this.isPlaying = !this.isPlaying

      if (this.isPlaying) {
        this.travelTimes = MappingManager.getInstance().getTravelTimes()
        MappingManager.getInstance().flyAlongCourse(this.store.getters.CURRENT_COURSE_ID)
      } else {
        MappingManager.getInstance().pauseFlyingAlongCourse()
      }
    },
    stopPlay: function () {
      if (this.isPlaying) {
        this.togglePlay()
      }
    },

    setProgress: function (ratio: number, inIndex: number) {
      if (this.elevationProfile?.mValues) {
        this.progressValue = 100 * ratio

        const index = Math.round(ratio * this.elevationProfile.mValues.length)

        if (index && index < this.elevationProfile.mValues.length) {
          this.traveledDistance = Math.round(((ratio * this.elevationProfile.mValues[this.elevationProfile.mValues.length - 1]) / 1000) * 100) / 100
        }

        if (ratio >= 0.99) {
          this.isPlaying = false
          // this.progressValue = 0
        }
      }
    },

    handleSwitchMapMode: function (mode: number): void {
      this.showPlay = mode === 3
    },

    progressChange: function (ev: any, time: any): void {
      if (ev?.target) {
        const elevProfile: ElevProfileResult = this.elevationProfile
        this.isPlaying = false
        const indexAtPercentage = Math.round(elevProfile.coordinates.length * (ev?.target.value / 100))

        if (elevProfile?.coordinates?.length > indexAtPercentage) {
          this.setProgress(ev?.target.value / 100, indexAtPercentage)
          MappingManager.getInstance().handleHoverCourseLine(elevProfile.coordinates[indexAtPercentage])

          if (this.travelTimes?.length > indexAtPercentage) {
            EventsManager.getInstance().eventEmitter.emit('dragPlayProgress' as any, { time: this.travelTimes[indexAtPercentage] })
          }
        }
      }
    }
  },
  mounted: function () {
    // Increment progress
    EventsManager.getInstance().eventEmitter.on('setPlayProgress', (e: any) => this.setProgress(e.value, e.index))
    EventsManager.getInstance().eventEmitter.on('stopPlay', (e: any) => this.stopPlay())
    EventsManager.getInstance().eventEmitter.on('switchMapMode', (e: any) => this.handleSwitchMapMode(e))

    this.store = useStore()
    const currentCourseId = computed(() => this.store.getters.CURRENT_COURSE_ID)
    const updateElevationProfile_: any = this.updateElevationProfile

    watch(currentCourseId, function (id: string, prevId: string) {
      const elevationProfile: ElevProfileResult | undefined = MappingManager.getInstance().getElevationProfile(id)

      if (elevationProfile) {
        updateElevationProfile_(elevationProfile)
      }
    })
  }
})
