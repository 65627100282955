
import EventsManager from '@/shared/eventsManager'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'LoadingProgress',
  data () {
    return {
      visible: false
    }
  },
  mounted: function () {
    EventsManager.getInstance().eventEmitter.on('showLoading', (e: any) => this.showLoading(e.visible))
  },
  methods: {
    showLoading: function (isVisible: boolean) {
      this.visible = isVisible
    }
  }
})
