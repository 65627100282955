import * as featureServiceTools from '@esri/arcgis-rest-feature-service'
import ConfigManager from './configManager'
import { CoursesInfos, ElevProfileResult } from '@/models/courses'

export default class QueryManager {
  public static GAP_FROM_GROUND = 52

  /**
   * Convert ESRI JSON to GeoJSON
   * @param features The features
   * @returns The result (geojson)
   */
  private static async esriJsonToGeoJSON (geoJsonRes: any, url: string, where = '1=1'): Promise<any> {
    const res: any = await featureServiceTools.queryFeatures({
      url: url,
      where: where,
      f: 'json',
      returnM: true,
      returnZ: true
    })

    if (res.features) {
      geoJsonRes.features = geoJsonRes.features.map((feat: any, i: number) => {
        feat.geometry.coordinates = feat.geometry.coordinates.map((coords: number[]) => {
          if (coords?.length > 1) {
            coords[2] += this.GAP_FROM_GROUND
          }

          return coords
        })

        feat.coordinatesM = res.features.length >= i && res.features[i].geometry?.paths?.length ? res.features[i].geometry.paths : []
        return feat
      })

      return geoJsonRes
    }
  }

  /**
   * Query ESRI Layer
   * @param url The layer url
   * @param where The where clause
   * @param returnM Return M values
   * @returns The result (esri features)
   */
  public static async queryEsriLayer (url: string, where = '1=1', returnM = false): Promise<any> {
    try {
      const res: any = await featureServiceTools.queryFeatures({
        url: url,
        where: where,
        f: 'geojson',
        returnM: returnM,
        returnZ: true
      })

      if (res.features) {
        if (returnM) {
          return QueryManager.esriJsonToGeoJSON(res, url, where)
        } else {
          return res
        }
      }

      return null
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * Compute elevation profile¨from geoprocessing
   * @param coords The line coords
   */
  public static async computeElevationProfileGP (coords: number[]): Promise<ElevProfileResult | undefined> {
    try {
      const elevProfile: ElevProfileResult = { distance: 0, coordinates: [], mValues: [], zValues: [] }

      const lineFeature = {
        features: [
          {
            aggregateGeometries: null,
            attributes: {
              OID: 1
            },
            geometry: {
              paths: coords,
              spatialReference: {
                latestWkid: 4326,
                wkid: 4326
              }
            },
            popupTemplate: null,
            symbol: null
          }
        ],
        fields: [
          {
            alias: 'OID',
            editable: true,
            length: -1,
            name: 'OID',
            nullable: true,
            type: 'esriFieldTypeOID'
          }
        ]
      }

      const formData = new FormData()
      formData.append('f', 'json')
      formData.append('InputLineFeatures', JSON.stringify(lineFeature))
      formData.append('ProfileIDField', 'OID')
      formData.append('DEMResolution', 'FINEST')
      formData.append('MaximumSampleDistance', '13')
      formData.append('MaximumSampleDistanceUnits', 'Meters')
      formData.append('returnZ', 'true')
      formData.append('returnM', 'true')

      const res: any = await fetch(ConfigManager.getInstance().config.data.esriElevationProfileGP, {
        method: 'POST',
        body: formData
      })

      if (res) {
        const json: any = await res.json()

        if (json?.results?.length) {
          const value: any = json.results[0].value

          if (value?.features?.length) {
            const feature: any = value.features[0]
            const paths: number[][][] = feature.geometry.paths

            elevProfile.distance = feature.attributes.ProfileLength
            elevProfile.coordinates = paths[0].map((coord: number[]) => [coord[0], coord[1], coord[2] + this.GAP_FROM_GROUND])
            elevProfile.zValues = paths[0].map((coord: number[]) => Math.round(coord[2]))
            elevProfile.mValues = paths[0].map((coord: number[]) => Math.round(coord[3]))
          }
        }

        return elevProfile
      }
    } catch (err) {
      return Promise.reject(err)
    }
  }

  /**
   * Compute elevation profile
   * @param coords The line coords
   * @param courseInfos The course info
   */
  public static computeElevationProfile (coords: number[][][], courseInfos: CoursesInfos | undefined): ElevProfileResult | undefined {
    return {
      distance: 0,
      coordinates: coords[0].map((coord: number[]) => [coord[0], coord[1], coord[2] + this.GAP_FROM_GROUND]),
      zValues: coords[0].map((coord: number[]) => Math.round(coord[2])),
      mValues: coords[0].map((coord: number[]) => Math.round(coord[3])),
      courseInfos: courseInfos
    }
  }
}
