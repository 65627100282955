import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/pushpin.png'


const _withScopeId = n => (_pushScopeId("data-v-4c863ec8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "courseName" }
const _hoisted_2 = { class: "rightDiv" }
const _hoisted_3 = { class: "courseInfo distance" }
const _hoisted_4 = { class: "value" }
const _hoisted_5 = {
  key: 0,
  class: "separator"
}
const _hoisted_6 = {
  key: 1,
  class: "courseInfo"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = {
  key: 2,
  class: "separator"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: "raceItem",
    onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.openCourse && _ctx.openCourse(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.courseInfos_.properties.course), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString((_ctx.courseInfos_.properties.distance / 1000).toFixed(2)) + " km", 1)
      ]),
      (!_ctx.isMobileView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : _createCommentVNode("", true),
      (!_ctx.isMobileView)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _createElementVNode("a", {
                href: `./Data/${_ctx.courseInfos_.properties.id}.gpx`,
                target: "_blank"
              }, " GPX ", 8, _hoisted_7)
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.courseInfos_?.properties.rdvLongitude)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8))
        : _createCommentVNode("", true),
      (_ctx.courseInfos_?.properties.rdvLongitude)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "courseInfo",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.zoomToTrainingLocation($event)))
          }, [
            _createElementVNode("img", {
              class: _normalizeClass(["downloatIcon", {expanded: _ctx.index === _ctx.expandedRace}]),
              src: _imports_0,
              title: "Lieu du RDV"
            }, null, 2)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}