import mitt from 'mitt'

type Events = {
    openElevationProfile: string;
    closeElevationProfile: string;
    showLoading: string;
    setPlayProgress: string;
    dragPlayProgress: string;
    stopPlay: string;
    clearSearch: string;
    switchMapMode: string;
    collapseSearch: string;
}

export default class EventsManager {
    private static instance: EventsManager
    public eventEmitter = mitt<Events>();

    /**
     * Get MappingManager instance
     */
    public static getInstance (): EventsManager {
      if (!EventsManager.instance) {
        EventsManager.instance = new EventsManager()
      }

      return EventsManager.instance
    }
}
