import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import VueApexCharts from 'vue3-apexcharts'
import VueMobileDetection from 'vue-mobile-detection'

const app = createApp(App)
app.use(store)
app.use(VueApexCharts)
app.use(VueMobileDetection)
app.mount('#app')
