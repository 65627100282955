
import ConfigManager from '@/shared/configManager'
import MappingManager from '@/shared/mappingManager'
import { defineComponent } from 'vue'
import store from '@/store'
import ToolsManager from '@/shared/toolsManager'

export default defineComponent({
  name: 'AppHeader',
  data () {
    return {
      mode: ConfigManager.getInstance().config.cesiumOptions.defaultMode,
      enable3D: ConfigManager.getInstance().config.cesiumOptions.enable3D && !ToolsManager.getInstance().isMobileView
    }
  },
  methods: {
    switchMapMode: function (mode: number): void {
      this.mode = mode
      MappingManager.getInstance().switchMapMode(mode)
      store.commit('setCurrentMapMode', mode)
    }
  }
})
