import Config from '@/models/config'

export default class ConfigManager {
  private static instance: ConfigManager
  public config: Config = null as any

  /**
   * Load config
   * @returns
   */
  public async loadConfig (): Promise<void> {
    try {
      const res = await fetch(`./config/config.json?date=${new Date().getTime()}`)
      this.config = await res.json()
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  }

  /**
   * Get config manager instance
   */
  public static getInstance (): ConfigManager {
    if (!ConfigManager.instance) {
      ConfigManager.instance = new ConfigManager()
    }

    return ConfigManager.instance
  }
}
