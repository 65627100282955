import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/arrow-right.png'


const _withScopeId = n => (_pushScopeId("data-v-74fff02f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "title actionStyle" }
const _hoisted_2 = {
  key: 0,
  class: "close",
  alt: "Collapse",
  src: _imports_0
}

export function render(_ctx, _cache) {
  return (_ctx.visible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "sideNav",
        style: _normalizeStyle({ width: `${_ctx.expanded_ ? _ctx.width : 42}px`, height: `${_ctx.expanded_ ? _ctx.height : 220}px` })
      }, [
        _createElementVNode("div", {
          class: "header",
          onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.toggle && _ctx.toggle(...args)))
        }, [
          _createElementVNode("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "sideNavTitle", {}, undefined, true)
          ]),
          (_ctx.expanded_ && _ctx.position === 'right')
            ? (_openBlock(), _createElementBlock("img", _hoisted_2))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["content", { 'minimized': !_ctx.expanded_}])
        }, [
          _renderSlot(_ctx.$slots, "sideNavContent", {}, undefined, true)
        ], 2)
      ], 4))
    : _createCommentVNode("", true)
}