export default class ToolsManager {
    private static instance: ToolsManager
    public isMobileView = false

    /**
     * Get MappingManager instance
     */
    public static getInstance (): ToolsManager {
      if (!ToolsManager.instance) {
        ToolsManager.instance = new ToolsManager()
      }

      return ToolsManager.instance
    }

    public static calculateSvgSize (text: string, fontSize: number, fontFamily: string): { width: number, height: number } {
      const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
      svg.setAttribute('id', 'tempSvg')
      svg.setAttribute('visibility', 'hidden')
      document.body.appendChild(svg)
      const textElement = document.createElementNS('http://www.w3.org/2000/svg', 'text')
      textElement.setAttribute('font-size', `${fontSize}px`)
      textElement.setAttribute('font-family', fontFamily)
      textElement.textContent = text
      svg.appendChild(textElement)
      const bbox = textElement.getBBox()
      document.body.removeChild(svg)
      return { width: bbox.width, height: bbox.height }
    }

    public static getSvgUri (text: string, fontSize: number, fontFamily: string, svgSize: { width: number, height: number }): string {
      return 'data:image/svg+xml,' + encodeURIComponent(`
      <svg xmlns="http://www.w3.org/2000/svg"> width="${svgSize.width}" height="${svgSize.height}"
        <defs>
        <filter id="whiteOutlineEffect" color-interpolation-filters="sRGB">
          <feMorphology in="SourceAlpha" result="MORPH" operator="dilate" radius="2" />
          <feColorMatrix in="MORPH" result="WHITENED" type="matrix" values="-1 0 0 0 1, 0 -1 0 0 1, 0 0 -1 0 1, 0 0 0 1 0"/>
          <feMerge>
            <feMergeNode in="WHITENED"/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        </defs>
        <rect width="100%" height="100%" fill="#C0C0C0" fill-opacity="0"/>
        <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-family="${fontFamily}" font-size="${fontSize}" fill="black" filter="url(#whiteOutlineEffect)">
        ${text}
        </text>
      '</svg>`)
    }
}
