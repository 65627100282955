
import { defineComponent, reactive } from 'vue'
import SideNav from '../../widgets/SideNav/SideNav.vue'
import RaceItem from './RaceItem/RaceItem.vue'
import { CourseCategories, CoursesInfos } from '@/models/courses'
import MappingManager from '@/shared/mappingManager'
import EventsManager from '@/shared/eventsManager'
import ToolsManager from '@/shared/toolsManager'

export default defineComponent({
  name: 'RacesList',
  components: {
    SideNav, RaceItem
  },
  data () {
    return {
      widgetExpanded: true,
      searhExpanded: false,
      sideNavHeight: window.innerHeight - 110,
      races: [] as CourseCategories[],
      expandedRace: -1,
      filterText: ''
    }
  },
  methods: {
    handleClearSearch: function () {
      this.filterText = ''
      this.filterCourses()
      EventsManager.getInstance().eventEmitter.emit('closeElevationProfile' as any)
    },
    collapseSearch: function () {
      this.searhExpanded = false
    },
    handleAccordion: function (selectedIndex: number) {
      this.expandedRace = this.expandedRace !== selectedIndex ? selectedIndex : -1
    },
    toggleWidgetExpanded: function () {
      this.widgetExpanded = !this.widgetExpanded
      // this.handleClearSearch()
    },
    toggleSearchExpanded: function () {
      this.searhExpanded = !this.searhExpanded
    },
    onFocusSearchInput: function () {
      this.searhExpanded = true
      this.filterCourses()
    },
    cleanSearchText: function () {
      return this.filterText.replace(/ \([\s\S]*?\)/g, '')
    },
    filterCourses: function () {
      let categories: CourseCategories[] = [...MappingManager.getInstance().getCategorizedCourses()]
      const searchText = this.cleanSearchText()

      if (searchText) {
        categories = categories.filter((cc: CourseCategories) => {
          const c = cc.category && cc.category.toUpperCase().indexOf(searchText.toUpperCase()) !== -1

          if (c) {
            return true
          }

          const items: CoursesInfos[] = cc.courseInfos.filter((ci: CoursesInfos) => ci && ci.properties && ci.properties.course && ci.properties.course.toUpperCase().indexOf(searchText.toUpperCase()) !== -1)
          return items.length
        })

        this.expandedRace = categories.length === 1 ? 0 : -1
      } else {
        MappingManager.getInstance().refreshCourses()
      }

      this.races = reactive(categories)
    },
    onClickItem: function (courseDistance: {course: string, distance: number}) {
      this.filterText = `${courseDistance.course} (${(courseDistance.distance / 1000).toFixed(2)} km)`
      this.searhExpanded = false
      EventsManager.getInstance().eventEmitter.emit('openElevationProfile' as any)

      // Collapse the widget if mobile view
      if (ToolsManager.getInstance().isMobileView) {
        setTimeout(() => {
          this.widgetExpanded = false
        }, 500)
      }
    }
  },
  mounted: function () {
    this.races = reactive(MappingManager.getInstance().getCategorizedCourses())
    EventsManager.getInstance().eventEmitter.on('clearSearch', this.handleClearSearch)
    EventsManager.getInstance().eventEmitter.on('collapseSearch', this.collapseSearch)
  }
})
