import { ElevProfileResult } from '@/models/courses'
import { createStore } from 'vuex'

export default createStore({
  state: {
    currentCourseId: '',
    currentMapMode: -1,
    elevationProfile: null
  },
  getters: {
    CURRENT_COURSE_ID: (state) => state.currentCourseId,
    CURRENT_MAP_MODE: (state) => state.currentMapMode
  },
  mutations: {
    setCurrentCourseId (state: any, id: string) {
      state.currentCourseId = id
    },
    setCurrentMapMode (state: any, mode: number) {
      state.currentMapMode = mode
    }
  },
  actions: {
  },
  modules: {
  }
})
