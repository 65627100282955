import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5cfaa8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.enable3D)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["actionBtn angled", { 'active': _ctx.mode === 3}])
        }, [
          _createElementVNode("span", {
            class: "actionStyle",
            onClick: _cache[0] || (_cache[0] = $event => (_ctx.switchMapMode(3)))
          }, "        3D        ")
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.enable3D)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["actionBtn angled", { 'active': _ctx.mode === 2}])
        }, [
          _createElementVNode("span", {
            class: "actionStyle",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.switchMapMode(2)))
          }, "        2D        ")
        ], 2))
      : _createCommentVNode("", true)
  ]))
}