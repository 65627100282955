
import { defineComponent } from 'vue'
import MappingManager from '../../shared/mappingManager'
import EventsManager from '@/shared/eventsManager'
import NavigationControls from '@/components/CesiumViewer/NavigationControls/NavigationControls.vue'

export default defineComponent({
  name: 'CesiumViewer',
  components: {
    NavigationControls
  },
  async mounted (): Promise<void> {
    EventsManager.getInstance().eventEmitter.emit('showLoading' as any, { visible: true })
    await MappingManager.getInstance().initViewer()
    EventsManager.getInstance().eventEmitter.emit('showLoading' as any, { visible: false })
  }
})
