
import ConfigManager from '@/shared/configManager'
import EventsManager from '@/shared/eventsManager'
import MappingManager from '@/shared/mappingManager'
import ToolsManager from '@/shared/toolsManager'
import { defineComponent, watch, computed } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'NavigationControls',
  data () {
    return {
      geolocationEnabled: ToolsManager.getInstance().isMobileView && navigator?.geolocation,
      geolocationOn: false,
      store: null as any,
      selectedBtnIndex: 0,
      rotationEnabled: false,
      mapMode: ConfigManager.getInstance().config.cesiumOptions.defaultMode,
      isMobileView: ToolsManager.getInstance().isMobileView,
      locationLoading: false
    }
  },
  methods: {
    flyToHomePosition: function (): void {
      EventsManager.getInstance().eventEmitter.emit('clearSearch' as any)
      EventsManager.getInstance().eventEmitter.emit('collapseSearch' as any)
      MappingManager.getInstance().flyToHomePosition()
      if (this.rotationEnabled) {
        this.toggleMouseLeftRotation()
      }
    },
    zoomIn: function (): void {
      MappingManager.getInstance().zoomIn()
      if (this.rotationEnabled) {
        this.toggleMouseLeftRotation()
      }
    },
    zoomOut: function (): void {
      MappingManager.getInstance().zoomOut()
      if (this.rotationEnabled) {
        this.toggleMouseLeftRotation()
      }
    },
    toggleMouseLeftRotation: function (): void {
      this.rotationEnabled = !this.rotationEnabled
      MappingManager.getInstance().setMouseLeftRotation(this.rotationEnabled)
    },
    updateMapMode: function (mode: number): void {
      this.mapMode = mode
      if (this.rotationEnabled) {
        this.toggleMouseLeftRotation()
      }
    },
    locateMe: function () {
      this.geolocationOn = !this.geolocationOn

      // Get position
      if (this.geolocationEnabled && this.geolocationOn) {
        this.locationLoading = true

        navigator.geolocation.getCurrentPosition((location: any) => {
          if (location?.coords?.latitude && location?.coords?.longitude) {
            MappingManager.getInstance().locateMe(location.coords.latitude, location.coords.longitude, true)
          }

          this.locationLoading = false
        },
        (err: any) => {
          this.locationLoading = false
          console.error(err)
        })
      } else {
        MappingManager.getInstance().removeLocateMe()
        this.locationLoading = false
      }
    },
    watchLocation: function (): void {
      navigator.geolocation.watchPosition((location: any) => {
        if (this.geolocationOn) {
          MappingManager.getInstance().locateMe(location.coords.latitude, location.coords.longitude, true)
        }
      })
    },
    logCameraPos: function (): void {
      MappingManager.getInstance().logCameraPos()
      // MappingManager.getInstance().test()
    }
  },
  mounted: function () {
    if (this.geolocationEnabled) {
      this.watchLocation()
    }

    this.store = useStore()
    const currentMapMode = computed(() => this.store.getters.CURRENT_MAP_MODE)
    const updateMapMode_: any = this.updateMapMode

    watch(currentMapMode, function (mode: number, prevMode: number) {
      updateMapMode_(mode)
    })
  }
})
