import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/running.png'
import _imports_1 from '@/assets/icons/sort-down.png'
import _imports_2 from '@/assets/icons/close-colored.png'
import _imports_3 from '@/assets/icons/arrow-down.png'


const _withScopeId = n => (_pushScopeId("data-v-13fb736e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "races" }
const _hoisted_2 = ["onClick"]

export function render(_ctx, _cache) {
  const _component_RaceItem = _resolveComponent("RaceItem")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["searchDiv", {expanded: _ctx.widgetExpanded}])
    }, [
      (_ctx.widgetExpanded)
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.filterText) = $event)),
            type: "text",
            class: "searchInput",
            placeholder: "Chercher une course / un entraînement",
            onFocus: _cache[1] || (_cache[1] = (...args) => (_ctx.onFocusSearchInput && _ctx.onFocusSearchInput(...args))),
            onInput: _cache[2] || (_cache[2] = (...args) => (_ctx.filterCourses && _ctx.filterCourses(...args)))
          }, null, 544)), [
            [_vModelText, _ctx.filterText]
          ])
        : _createCommentVNode("", true),
      _createElementVNode("img", {
        class: "searchIcon",
        alt: "Chercher une course",
        title: "Chercher une course",
        src: _imports_0,
        onClick: _cache[3] || (_cache[3] = (...args) => (_ctx.toggleWidgetExpanded && _ctx.toggleWidgetExpanded(...args)))
      }),
      (_ctx.widgetExpanded && !_ctx.filterText)
        ? (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: _normalizeClass(["downIcon", {rotate: _ctx.searhExpanded}]),
            alt: "Chercher une course",
            title: "Chercher une course",
            src: _imports_1,
            onClick: _cache[4] || (_cache[4] = (...args) => (_ctx.toggleSearchExpanded && _ctx.toggleSearchExpanded(...args)))
          }, null, 2))
        : _createCommentVNode("", true),
      (_ctx.widgetExpanded && _ctx.filterText)
        ? (_openBlock(), _createElementBlock("img", {
            key: 2,
            class: "downIcon",
            alt: "Vider la recherche",
            title: "Vider la recherche",
            src: _imports_2,
            onClick: _cache[5] || (_cache[5] = (...args) => (_ctx.handleClearSearch && _ctx.handleClearSearch(...args)))
          }))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: _normalizeClass(["searchContent", {expanded: _ctx.widgetExpanded && _ctx.races && _ctx.races.length && _ctx.searhExpanded}])
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.races, (race, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: race.category,
          class: "accodionItem"
        }, [
          _createElementVNode("button", {
            class: "expandButton",
            onClick: _withModifiers(() => _ctx.handleAccordion(index), ["stop"])
          }, [
            _createTextVNode(_toDisplayString(race.category) + " ", 1),
            _createElementVNode("img", {
              class: _normalizeClass(["downIcon", {expanded: index === _ctx.expandedRace}]),
              src: _imports_3
            }, null, 2)
          ], 8, _hoisted_2),
          _createElementVNode("div", {
            class: _normalizeClass(["collapse", { 'hidden': index !== _ctx.expandedRace}])
          }, [
            (race.courseInfos && race.courseInfos.length)
              ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(race.courseInfos, (courseInfos, index2) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: courseInfos.id
                  }, [
                    (courseInfos && courseInfos.properties && courseInfos.properties.course && courseInfos.properties.course.toUpperCase().indexOf(_ctx.cleanSearchText().toUpperCase()) !== -1)
                      ? (_openBlock(), _createBlock(_component_RaceItem, {
                          key: 0,
                          courseInfos: courseInfos,
                          onOpenCourseItem: _ctx.onClickItem
                        }, null, 8, ["courseInfos", "onOpenCourseItem"]))
                      : _createCommentVNode("", true)
                  ], 64))
                }), 128))
              : _createCommentVNode("", true)
          ], 2)
        ]))
      }), 128))
    ], 2)
  ]))
}