import * as Cesium from 'cesium'
import 'cesium/Build/Cesium/Widgets/widgets.css'
import Config, { Bookmark, CoursesInfosFields, IconStyle } from '@/models/config'
import ConfigManager from '@/shared/configManager'
import QueryManager from '@/shared/queryManager'
import store from '@/store'
import html2canvas from 'html2canvas'

import { Renseignements, CoursesDepartsArrivees, EntrainementsDepartsArrivees, SensParcours, Courses, Entrainements, CoursesInfos, CourseCategories, GeoJSONFeature, ElevProfileResult } from '@/models/courses'
import EventsManager from './eventsManager'
import ToolsManager from './toolsManager'

interface CourseElevationProfile {
  id: string
  elevationProfile: ElevProfileResult
}

interface CameraState {
  position: Cesium.Cartesian3
  direction: Cesium.Cartesian3
  heading: number
  pitch: number
  roll: number
  up: Cesium.Cartesian3
  right: Cesium.Cartesian3
  transform: Cesium.Matrix4
  frustum: any
  lat: number | null
  long: number | null
  height: number | null
  rectangle: Cesium.Rectangle | undefined,
  distance: number
}

export default class MappingManager {
  private VIEWER_CONTAINER_ID = 'cesiumMapContainer'
  private ZOOM_IN_OUT_AMOUNT_DEFAULT = 60
  private ZOOM_IN_OUT_AMOUNT_MOBILE = 500
  private CLAMP_TO_GROUD = true
  private COURSE_HEIGHT_FROM_GROUND = 0

  private DEFAULT_VIEW_LABEL_2D = 'Périmètre de la manifestation'
  private DEFAULT_VIEW_LABEL_3D = 'Survolez le périmètre de la manifestation'

  private static instance: MappingManager
  private renseignements: Renseignements[] = []
  private courses: Courses[] = []
  private coursesFeatureSet: any
  private coursesDS: Cesium.GeoJsonDataSource = null as any
  private coursesInfos: CoursesInfos[] = []
  private coursesEelevationProfile: CourseElevationProfile[] = []
  private graphicsDS: Cesium.GeoJsonDataSource = null as any
  private config: Config = null as any
  private flyEntity: Cesium.Entity = null as any
  private courseEntity: Cesium.Entity = null as any
  private hoverEntity: Cesium.Entity = null as any
  private trainingLocationEntity: Cesium.Entity = null as any
  private locateMeEntity: Cesium.Entity = null as any
  private centerEntity: Cesium.Entity = null as any
  private zoomEntity: Cesium.Entity = null as any
  private velocityVectorProperty: Cesium.VelocityVectorProperty = null as any
  private sampledPositionProperty: Cesium.SampledPositionProperty = null as any
  private streetEntities: Cesium.CustomDataSource = new Cesium.CustomDataSource('StreetNames')
  // Entity Groups
  private renseignementsDS: Cesium.CustomDataSource = new Cesium.CustomDataSource()
  private streetsDS: Cesium.CustomDataSource = new Cesium.CustomDataSource()
  private kmDS: Cesium.CustomDataSource = new Cesium.CustomDataSource()
  private buildingsProvider: any = null
  private terrainProvider: any = null
  private endTime: Cesium.JulianDate = null as any
  private currentCourseId = ''
  private cameraSate: CameraState = null as any
  private mapMode = 3
  private enable3D = false
  private popupDiv: HTMLSpanElement = null as any
  private travelTimes: any[] = []
  private dragTime: Cesium.JulianDate = null as any
  private positionsSeconds: number[] = []
  private isOrbiting = false
  private orbitEvent = null as any

  public stylesIcons: any = {
    renseignements: {},
    pk: {},
    startFinish: {},
    icons: {}
  }

  public viewer: Cesium.Viewer = null as any

  constructor () {
    this.config = ConfigManager.getInstance().config
    this.enable3D = this.config.cesiumOptions.enable3D && !ToolsManager.getInstance().isMobileView
    this.mapMode = this.enable3D ? this.config.cesiumOptions.defaultMode : 2
  }

  private initPopupDiv (): void {
    this.popupDiv = document.createElement('span')
    this.popupDiv.className = 'cesium-popup'
    this.viewer.container.appendChild(this.popupDiv)
  }

  private showPopup (position:any, content: string): void {
    if (position && content) {
      const canvasPosition = this.viewer.scene.cartesianToCanvasCoordinates(position)

      if (canvasPosition) {
        this.popupDiv.classList.add('show')
        this.popupDiv.style.top = canvasPosition.y + 'px'
        this.popupDiv.style.left = canvasPosition.x + 'px'
        this.popupDiv.innerHTML = content
      }
    } else {
      this.hidePopup()
    }
  }

  // Function to hide the popup
  private hidePopup (): void {
    this.popupDiv.classList.remove('show')
  }

  /**
   * Init kilometric datasource
   */
  private initKMDatasource (): void {
    this.viewer.dataSources.add(this.kmDS)
  }

  /**
   * Load 3D terrain
   * @returns Promise
   */
  private async load3DTerrain (): Promise<void> {
    try {
      await this.loadESRI3DTerrain()
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  }

  private async loadESRI3DTerrain (): Promise<void> {
    this.viewer.scene.terrainProvider = await Cesium.ArcGISTiledElevationTerrainProvider.fromUrl(
      'https://elevation3d.arcgis.com/arcgis/rest/services/WorldElevation3D/Terrain3D/ImageServer'
    )
  }

  private async loadOSM3DTerrain (): Promise<void> {
    this.viewer.terrainProvider = new Cesium.EllipsoidTerrainProvider()
    this.terrainProvider = new Cesium.OpenStreetMapImageryProvider({
      // url: 'https://a.tile.openstreetmap.org/'
      url: this.config.data.terrainUrl
    })

    this.viewer.imageryLayers.addImageryProvider(this.terrainProvider)
  }

  /**
   * Load 3D data
   * @returns Promise
   */
  private async load3DData (): Promise<void> {
    try {
      await this.loadGoogle3DBuildings()
      // await this.loadEsri3DBuildings()
      // await this.loadSwisstopo3DBuildings()
      // this.loadOSM3DBuildings()
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  }

  /**
   * Load Google 3D Buildings
   */
  private async loadGoogle3DBuildings (): Promise<void> {
    Cesium.GoogleMaps.defaultApiKey = this.config.cesiumOptions.tokens.google
    this.buildingsProvider = await Cesium.createGooglePhotorealistic3DTileset()
    this.viewer.scene.primitives.add(this.buildingsProvider)
    this.buildingsProvider.style = new Cesium.Cesium3DTileStyle({
      color: `color("white", ${this.config.cesiumOptions.view3D.google3DTilesTransparency})`
    })
  }

  /**
   * Load Esri 3D Buildings
   */
  private async loadEsri3DBuildings (): Promise<void> {
    const i3sLayer = 'https://ags110-demo.arxit.com/server/rest/services/Hosted/CAD_BATI3D_PAQUETAGE/SceneServer'

    this.buildingsProvider = await Cesium.I3SDataProvider.fromUrl(this.config.data.esri3DBuildings, {
      // geoidTiledTerrainProvider: geoidService
    })

    await this.viewer.scene.primitives.add(this.buildingsProvider)
    try {
      // Initialize a terrain provider which provides geoid conversion between gravity related (typically I3S datasets) and ellipsoidal based
      // height systems (Cesium World Terrain).
      // If this is not specified, or the URL is invalid no geoid conversion will be applied.
      // The source data used in this transcoding service was compiled from https://earth-info.nga.mil/#tab_wgs84-data and is based on EGM2008 Gravity Model
      const geoidService = await Cesium.ArcGISTiledElevationTerrainProvider.fromUrl(
        'https://tiles.arcgis.com/tiles/z2tnIkrLQ2BRzr6P/arcgis/rest/services/EGM2008/ImageServer'
      )

      const cesium3dTilesetOptions = {
        skipLevelOfDetail: false,
        debugShowBoundingVolume: false,
        style: new Cesium.Cesium3DTileStyle({
          backgroundColor: 'color("blue")',
          backgroundEnabled: 'true'
        })
      }

      const i3sOptions = {
        traceFetches: false,
        geoidTiledTerrainProvider: geoidService,
        cesium3dTilesetOptions: cesium3dTilesetOptions
      }

      // Create I3S data provider
      const i3sProvider = await Cesium.I3SDataProvider.fromUrl(
        i3sLayer,
        i3sOptions
      )
    } catch (error) {
      console.log(
        `There was an error creating the I3S Data Provider: ${error}`
      )
    }
  }

  /**
   * Load OSM Buildings
   */
  private async loadOSM3DBuildings (): Promise<void> {
    const osmBuildingsTileset = await Cesium.createOsmBuildingsAsync()
    this.viewer.scene.primitives.add(osmBuildingsTileset)
  }

  private setCameraState (): void {
    const lookAt: { lat: number, long: number, height: number } | null = this.cameraLookingAt()
    this.cameraSate = {
      position: this.viewer.camera.position,
      direction: this.mapMode === 2 ? this.viewer.camera.direction : this.cameraSate?.direction,
      heading: this.mapMode === 2 ? this.viewer.camera.heading : this.cameraSate?.heading,
      pitch: this.mapMode === 2 ? this.viewer.camera.pitch : this.cameraSate?.pitch,
      roll: this.mapMode === 2 ? this.viewer.camera.roll : this.cameraSate?.roll,
      up: this.viewer.camera.up,
      right: this.viewer.camera.right,
      transform: this.viewer.camera.transform,
      frustum: this.viewer.camera.frustum,
      lat: lookAt ? lookAt.lat : null,
      long: lookAt ? lookAt.long : null,
      height: lookAt ? lookAt.height : null,
      rectangle: this.viewer.camera.computeViewRectangle(this.viewer.scene.globe.ellipsoid),
      distance: this.viewer.camera.positionCartographic.height
    }
  }

  /**
   * Set camera rotation enabled / disabled
   * @param enabled Rotation is enabled / disabled
   */
  private setCameraRotationEnabled (enabled: boolean): void {
    this.viewer.scene.screenSpaceCameraController.enableTilt = enabled
  }

  /**
   * Set viewer cursor
   * @param isFreeRotation is free rotation
   */
  private setViewerCursor (isFreeRotation: boolean): void {
    const element: any = document.getElementById(this.VIEWER_CONTAINER_ID)

    if (element) {
      if (isFreeRotation) {
        element.classList.add('freeRotation')
      } else {
        element.classList.remove('freeRotation')
        element.style.cursor = 'default'
      }
    }
  }

  private zoomToConfigView (): void {
    this.viewer.scene.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(this.config.cesiumOptions.view3D.camera.destination.x,
        this.config.cesiumOptions.view3D.camera.destination.y,
        this.config.cesiumOptions.view3D.camera.destination.z),
      orientation: {
        heading: Cesium.Math.toRadians(this.config.cesiumOptions.view3D.camera.orientation.heading),
        pitch: this.mapMode === 3 ? Cesium.Math.toRadians(this.config.cesiumOptions.view3D.camera.orientation.pitch) : Cesium.Math.toRadians(-90)
      }
    })
  }

  private updateCameraController () {
    this.viewer.scene.camera.changed.addEventListener(() => {
      this.handeNavigationArea()
    })
  }

  /**
   * Limit navigation to restricted area
   */
  private handeNavigationArea (): void {
    const restrictedArea = Cesium.Rectangle.fromDegrees(this.config.cesiumOptions.view3D.camera.restrictedArea.west,
      this.config.cesiumOptions.view3D.camera.restrictedArea.south,
      this.config.cesiumOptions.view3D.camera.restrictedArea.east,
      this.config.cesiumOptions.view3D.camera.restrictedArea.north)

    const cameraPosition = this.viewer.camera.positionCartographic

    // Ensure the camera longitude is within bounds
    if ((cameraPosition.longitude < restrictedArea.west) ||
        (cameraPosition.longitude > restrictedArea.east) ||
        (cameraPosition.latitude < restrictedArea.south) ||
        (cameraPosition.latitude > restrictedArea.north)) {
      this.zoomToConfigView()
    }
  }

  private initViewerEvents (): void {
    this.initDragPlayProgress()

    // If not mobile, show popup on hover
    this.viewer.screenSpaceEventHandler.setInputAction((movement: any) => {
      const element: any = document.getElementById(this.VIEWER_CONTAINER_ID)

      try {
        if (movement.endPosition) {
          if (!ToolsManager.getInstance().isMobileView) {
            const pickedObject = this.viewer.scene.pick(movement.endPosition)
            if (Cesium.defined(pickedObject) && pickedObject?.primitive) {
              if (pickedObject?.id?.id === 'DEFAULT_VIEW' && this.mapMode === 3) {
                this.isOrbiting = true
                element.style.cursor = 'pointer'
              } else if (pickedObject?.id?._name) {
                this.showPopup(pickedObject?.primitive?.position, pickedObject?.id?._name)
                this.isOrbiting = false
                this.setViewerCursor(this.viewer.scene.screenSpaceCameraController.tiltEventTypes === Cesium.CameraEventType.LEFT_DRAG)
              } else {
                this.endMoveEvent()
              }
            } else {
              this.endMoveEvent()
            }
          } else {
            this.endMoveEvent()
          }
        } else {
          this.endMoveEvent()
        }
      } catch (err) {
        console.error(err)
        this.endMoveEvent()
      }
    }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)

    // If mobile, show popup on click
    this.viewer.screenSpaceEventHandler.setInputAction((movement: any) => {
      try {
        if (movement.position) {
          if (ToolsManager.getInstance().isMobileView) {
            const pickedObject = this.viewer.scene.pick(movement.position)
            if (Cesium.defined(pickedObject) && pickedObject?.primitive) {
              this.showPopup(pickedObject?.primitive?.position, pickedObject?.id?._name)
            } else {
              this.hidePopup()
            }
          } else {
            this.hidePopup()
          }
        }
      } catch (err) {
        console.error(err)
      }
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    this.viewer.camera.moveStart.addEventListener(() => {
      this.hidePopup()
    })
  }

  private endMoveEvent (): void {
    const element: any = document.getElementById(this.VIEWER_CONTAINER_ID)
    this.hidePopup()
    this.isOrbiting = false
    this.setViewerCursor(this.viewer.scene.screenSpaceCameraController.tiltEventTypes === Cesium.CameraEventType.LEFT_DRAG)
  }

  /**
   * Init the viewer
    */
  public async initViewer (): Promise<void> {
    try {
      Cesium.Ion.defaultAccessToken = this.config.cesiumOptions.tokens.cesium

      this.viewer = new Cesium.Viewer(this.VIEWER_CONTAINER_ID, {
        sceneMode: this.mapMode === 2 ? Cesium.SceneMode.SCENE2D : Cesium.SceneMode.SCENE3D,

        // Very important to keep this 2 lines (to avoid to call bing map imagery provider)
        // imageryProvider: false,
        baseLayerPicker: false,
        infoBox: false,
        terrain: Cesium.Terrain.fromWorldTerrain()
      })

      // Zoom to config view
      this.zoomToConfigView()

      this.viewer.scene.globe.show = true
      this.viewer.scene.skyAtmosphere.show = true
      this.viewer.scene.skyBox.show = true
      this.viewer.clockViewModel.canAnimate = false
      this.viewer.fullscreenButton.viewModel.isFullscreenEnabled = false
      this.viewer.sceneModePicker.viewModel.duration = 0.0
      this.viewer.camera.defaultZoomAmount = ToolsManager.getInstance().isMobileView ? this.ZOOM_IN_OUT_AMOUNT_MOBILE : this.ZOOM_IN_OUT_AMOUNT_DEFAULT
      this.viewer.scene.globe.baseColor = Cesium.Color.WHITE
      const scene = this.viewer.scene
      scene.screenSpaceCameraController.minimumZoomDistance = this.config.cesiumOptions.minimumZoomDistance
      scene.screenSpaceCameraController.maximumZoomDistance = this.config.cesiumOptions.maximumZoomDistance
      scene.screenSpaceCameraController.inertiaZoom = 1

      // Optionally, adjust these parameters to control how the camera reacts to user input
      scene.screenSpaceCameraController.enableTilt = true
      scene.screenSpaceCameraController.enableRotate = true
      scene.screenSpaceCameraController.enableLook = false

      if (ToolsManager.getInstance().isMobileView) {
        (this.viewer.scene.screenSpaceCameraController as unknown as any)._zoomFactor = this.config.cesiumOptions.view2D.mobileZoomFactor
      }

      this.initPopupDiv()
      this.initViewerEvents()

      this.updateCameraController()
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Init the viewer data
    */
  public async initViewerData (): Promise<void> {
    try {
      // Init clock view model
      if (this.enable3D) {
        this.initClockVm()
      }

      // Init courses
      await this.initCoursesDatasource()

      // Fly to home position
      this.flyToHomePosition()

      // Init KM datasource
      this.initKMDatasource()

      // Add 3D terrain
      /* if (this.config.cesiumOptions.defaultMode === 3) {
        await this.load3DTerrain()
      } */

      // Add 3D model
      if (this.enable3D && this.mapMode === 3) {
        await this.load3DData()
        this.viewer.dataSources.add(this.streetEntities)
        this.addStreetNames()

        if (this.streetEntities) {
          this.streetEntities.show = true
        }
      }

      if (this.config.cesiumOptions.defaultMode !== 3) {
        this.switchMapMode(2)
      }

      // Load osm terrain
      if (this.mapMode === 2) {
        await this.loadOSM3DTerrain()

        if (this.streetEntities) {
          this.streetEntities.show = false
        }
      } else {
        this.hideTerrain()
      }

      this.setCameraState()

      // Add PI map pins
      this.addPIMapPins()
    } catch (err) {
      console.error(err)
    }
  }

  private initDragPlayProgress () {
    EventsManager.getInstance().eventEmitter.on('dragPlayProgress', (e: any) => {
      if (e?.time) {
        this.dragTime = e.time
      }
    })
  }

  /**
   * Init clock view model
   */
  private initClockVm (): void {
    if (this.viewer.clockViewModel) {
      this.viewer.clockViewModel.clock.onTick.addEventListener((clocktick: any) => {
        if (this.viewer.trackedEntity && this.viewer.clockViewModel.canAnimate) {
          const ratio = (clocktick.currentTime.secondsOfDay - clocktick.startTime.secondsOfDay) / (clocktick.stopTime.secondsOfDay - clocktick.startTime.secondsOfDay)

          if (ratio <= 1) {
            EventsManager.getInstance().eventEmitter.emit('setPlayProgress' as any, { value: ratio, time: clocktick.currentTime })
          }

          if (ratio >= 1 && this.flyEntity) {
            this.viewer.entities.remove(this.flyEntity)
            this.flyEntity = null as any
            this.pauseFlyingAlongCourse()
            this.sampledPositionProperty = null as any
            this.viewer.trackedEntity = undefined
          }
        }
      })
    }
  }

  /**
   * Load layer features
   * @param layerId The layer id
   * @returns The features and geometries
   */
  private async loadLayerFeatures (layerId: number, returnM = false): Promise<any> {
    try {
      const esriBaseUrl: string = this.config.data.esriBaseUrl
      const res = await QueryManager.queryEsriLayer(`${esriBaseUrl}/${layerId}`, '1=1', returnM)
      return res?.features ? res : null
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  }

  /**
   * Get courses infos
   * @param features The layer features
   * @returns The courses infos
   */
  private getCoursesInfos (features: GeoJSONFeature[]): CoursesInfos[] {
    const fields: CoursesInfosFields = this.config.data.coursesData.courses_infos.fields

    return features.map((feature: GeoJSONFeature) => {
      const properties = {
        id: feature.properties[fields.id],
        name: feature.properties[fields.name],
        distance: feature.properties[fields.distance],
        course: feature.properties[fields.course],
        positiveElevation: feature.properties[fields.positiveElevation],
        negativeElevation: feature.properties[fields.negativeElevation],
        distanceLabel: feature.properties[fields.distanceLabel],
        category: feature.properties[fields.category],
        rdvLongitude: feature.properties[fields.rdvLongitude] ? Number(feature.properties[fields.rdvLongitude]) : 0,
        rdvLatitude: feature.properties[fields.rdvLatitude] ? Number(feature.properties[fields.rdvLatitude]) : 0,
        lieuRdv: feature.properties[fields.lieuRdv] ? feature.properties[fields.lieuRdv] : ''
      }

      return { properties: properties, geometry: feature.geometry }
    })
  }

  /**
   * Filter courses entities
   * @param id The id of the course
   */
  private filterCoursesDs (id: string): void {
    this.coursesDS?.entities?.values?.forEach((entity: Cesium.Entity, i: number) => {
      entity.show = this.coursesFeatureSet.features[i].properties[this.config.data.coursesData.courses.fields.id] === id
    })
  }

  /**
   * Remove courses from the map
   */
  private removeCoursesFromMap (): void {
    this.filterCoursesDs('')
    this.kmDS.entities.removeAll()
    if (this.flyEntity) {
      this.viewer.entities.remove(this.flyEntity)
    }
  }

  /**
   * Compute the course flight
   * @param id The id of the course
   * @param start The start time
   * @returns SampledPositionProperty
   */
  private computeCourseFlight (id: string, start: Cesium.JulianDate): Cesium.SampledPositionProperty {
    const property = new Cesium.SampledPositionProperty()
    const courseElevProfile: CourseElevationProfile | undefined = this.coursesEelevationProfile.find((cds: CourseElevationProfile) => cds.id === id)
    this.travelTimes = []

    if (courseElevProfile?.elevationProfile?.coordinates?.length && courseElevProfile?.elevationProfile?.mValues?.length) {
      const maxM = this.getCourseDistance(id)
      const duration = this.getCourseDuration(id)

      courseElevProfile.elevationProfile?.coordinates.forEach((coord: number[], i: number) => {
        const currentM = courseElevProfile.elevationProfile.mValues[i]
        const seconds = !i ? 0 : (currentM / maxM) * duration

        const time = Cesium.JulianDate.addSeconds(
          start,
          seconds,
          new Cesium.JulianDate()
        )

        // this.positionsSeconds.push(seconds)

        this.travelTimes.push(time)

        const position = Cesium.Cartesian3.fromDegrees(
          coord[0],
          coord[1],
          coord[2]
        )

        property.addSample(time, position)

        if (i === courseElevProfile.elevationProfile?.coordinates.length - 1) {
          this.endTime = time
        }
      })
    }

    return property
  }

  /**
   * Get course distance
   * @param id The id of the course
   * @returns The distance of the course
   */
  private getCourseDistance (id: string): number {
    const courseElevProfile: CourseElevationProfile | undefined = this.coursesEelevationProfile.find((cds: CourseElevationProfile) => cds.id === id)

    if (courseElevProfile?.elevationProfile?.mValues?.length) {
      return courseElevProfile.elevationProfile?.mValues[courseElevProfile.elevationProfile?.mValues.length - 1]
    }

    return 0
  }

  /**
   * Get course duration
   * @param id The id of the course
   * @returns The course duration in seconds
   */
  private getCourseDuration (id: string): number {
    // Example : 2000m -> 40s
    return (this.getCourseDistance(id) / 100) * 2
  }

  /**
   * Init courses datasource
   */
  private async initCoursesDatasource (): Promise<void> {
    try {
      this.coursesDS = await Cesium.GeoJsonDataSource.load(this.coursesFeatureSet, {
        clampToGround: false,
        stroke: Cesium.Color.fromCssColorString('#00000000'), // Cesium.Color.fromCssColorString(this.config.data.coursesData.style.color),
        strokeWidth: 0.001 // this.config.data.coursesData.style.width
      })

      this.filterCoursesDs('')

      this.viewer.dataSources.add(this.coursesDS)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Set courses polylines width
   */
  private setCoursesPolylinesWidth (): void {
    const width: number = this.mapMode === 2 ? this.config.data.coursesData.style.width2D : this.config.data.coursesData.style.width3D

    this.coursesDS.entities.values.forEach((entity: Cesium.Entity) => {
      if (entity.polyline) {
        entity.polyline.width = width as any
      }
    })
  }

  /**
   * Get the current view distance
   * @returns The current view distance
   */
  private getCurrentViewingDistance (): number {
    const width = this.viewer.container.clientWidth
    const height = this.viewer.container.clientHeight
    const ray = this.viewer.camera.getPickRay(new Cesium.Cartesian2(width / 2, height / 2))

    if (ray) {
      const position = this.viewer.scene.globe.pick(ray, this.viewer.scene)
      if (position !== undefined) {
        return Cesium.Cartesian3.distance(this.viewer.camera.positionWC, position)
      }
    }

    return 500
  }

  /**
   * Compute the center entity
   * @param long The longitude of the entity
   * @param lat The latitude of the entity
   */
  private computeCenterEntity (long: number, lat: number) {
    if (this.centerEntity) {
      this.viewer.entities.remove(this.centerEntity)
    }
    const canvas = this.viewer.scene.canvas
    const center = new Cesium.Cartesian2(canvas.clientWidth / 2.0, canvas.clientHeight / 2.0)
    const ellipsoid = this.viewer.scene.globe.ellipsoid
    const result = this.viewer.camera.pickEllipsoid(center, ellipsoid)
    if (result) {
      this.centerEntity = new Cesium.Entity({
        position: result,
        billboard: {
          image: this.stylesIcons.icons.cross,
          heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
          show: false,
          width: 1,
          height: 1,
          scale: 0.0001,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.CENTER,
          alignedAxis: Cesium.Cartesian3.ZERO
        }
      })

      this.viewer.entities.add(this.centerEntity)
    }
  }

  /**
   * Load app data
   * @returns Promise res && res.features ? res.features as GeoJSONFeature[]
   */
  public async loadData (): Promise<void> {
    try {
      const renseignementsFS = await this.loadLayerFeatures(this.config.data.coursesData.renseignements.id)
      this.renseignements = renseignementsFS && renseignementsFS.features ? renseignementsFS.features as Renseignements[] : []

      this.coursesFeatureSet = await this.loadLayerFeatures(this.config.data.coursesData.courses.id, true)
      this.courses = this.coursesFeatureSet && this.coursesFeatureSet.features ? this.coursesFeatureSet.features as Courses[] : []

      const coursesInfosFS = await this.loadLayerFeatures(this.config.data.coursesData.courses_infos.id)
      if (coursesInfosFS && coursesInfosFS.features) {
        this.coursesInfos = this.getCoursesInfos(coursesInfosFS.features as CoursesInfos[])
      }

      return Promise.resolve()
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  }

  /**
   * Get categories of courses
   * @returns Categories of courses
   */
  public getCategorizedCourses (): CourseCategories[] {
    const courseCategories: CourseCategories[] = []

    if (this.coursesInfos) {
      this.coursesInfos
        .filter((ci: CoursesInfos) => {
          // Note default fake course
          if (ci.properties.id === this.config.data.coursesData.initialCourseIdZoomIn) {
            return false
          }

          // Check date for training
          if (ci.properties.lieuRdv) {
            return this.checkDateFromTrainingName(ci.properties.category)
          }

          return true
        })
        .forEach((ci: CoursesInfos) => {
          const courses: CourseCategories | undefined = courseCategories.find((cc: CourseCategories) => cc.category === ci.properties.category)

          if (!courses) {
            courseCategories.push({
              category: ci.properties.category,
              courseInfos: this.coursesInfos.filter((ci2: CoursesInfos) => ci2.properties.category === ci.properties.category)
            })
          }
        })
    }

    return courseCategories
  }

  /**
   * Get date from training name
   * @param name The training name
   */
  private checkDateFromTrainingName (name: string): boolean {
    const arr1: string[] = name.split(':')

    if (arr1 && arr1.length > 1) {
      const dateStr: string = arr1[1]

      if (dateStr) {
        const arr2: string[] = dateStr.trim().split('-')

        if (arr2 && arr2.length === 3) {
          const ts: number = Date.parse(`${arr2[2]}-${arr2[1]}-${arr2[0]}`)

          if (!isNaN(ts)) {
            const courseDate = new Date(ts)
            const currentDate = new Date()
            courseDate.setHours(0, 0, 0, 0)
            currentDate.setHours(0, 0, 0, 0)
            return courseDate.getTime() >= currentDate.getTime()
          }
        }
      }
    }

    return true
  }

  /**
   * Add map pins (PI)
   */
  private async addPIMapPins (): Promise<void> {
    try {
      if (this.renseignements?.length) {
        const pinBuilder = new Cesium.PinBuilder()
        const layerConfig = this.config.data.coursesData.renseignements

        this.renseignements.forEach(async (feature: GeoJSONFeature) => {
          const styleIcon: IconStyle | undefined = layerConfig.style.find((i: IconStyle) => i.id === feature.properties[layerConfig.fields.type])

          if (styleIcon) {
            const canvas = await pinBuilder.fromUrl(this.stylesIcons.renseignements[styleIcon.icon], Cesium.Color.fromCssColorString(styleIcon.color), 48)
            const position = Cesium.Cartesian3.fromDegrees(
              feature.geometry.coordinates[0],
              feature.geometry.coordinates[1],
              feature.geometry.coordinates[2] + QueryManager.GAP_FROM_GROUND + this.COURSE_HEIGHT_FROM_GROUND + 5
            )
            this.renseignementsDS.entities.add({
              name: feature.properties[layerConfig.fields.description],
              position: position,
              billboard: {
                // heightReference: Cesium.HeightReference.RELATIVE_TO_GROUND,
                image: canvas.toDataURL(),
                verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                horizontalOrigin: Cesium.HorizontalOrigin.CENTER
              }
            })
          }
        })

        this.viewer.dataSources.add(this.renseignementsDS)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Add street names
   */
  private addStreetNames (): void {
    try {
      if (this.renseignements?.length) {
        const layerConfig = this.config.data.coursesData.renseignements
        const type = 'Rue'

        this.renseignements.filter((feature: GeoJSONFeature) => feature.properties[layerConfig.fields.type] === type)
          .forEach(async (feature: GeoJSONFeature) => {
            if (feature.properties[layerConfig.fields.type] === type) {
              const position = Cesium.Cartesian3.fromDegrees(
                feature.geometry.coordinates[0],
                feature.geometry.coordinates[1],
                feature.geometry.coordinates[2] + QueryManager.GAP_FROM_GROUND + this.COURSE_HEIGHT_FROM_GROUND + 0.5
              )
              this.streetsDS.entities.add({
                name: feature.properties[layerConfig.fields.description],
                position: position,
                billboard: {
                  image: this.stylesIcons.icons.street,
                  // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
                  show: true,
                  width: 20,
                  height: 20,
                  horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                  verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                  alignedAxis: Cesium.Cartesian3.ZERO,
                  eyeOffset: new Cesium.Cartesian3(0, 0, -5)
                }
              })
            }
          })

        this.viewer.dataSources.add(this.streetsDS)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Add kilometric points
   * @param id The id of the course
   */
  private addKMPoints (id: string): void {
    const courseElevProfile: CourseElevationProfile | undefined = this.coursesEelevationProfile.find((cds: CourseElevationProfile) => cds.id === id)

    if (courseElevProfile?.elevationProfile?.coordinates?.length) {
      courseElevProfile.elevationProfile?.coordinates.forEach((coord: number[], i: number) => {
        const m: number = courseElevProfile.elevationProfile.mValues[i]
        if (m > 0 && m % 1000 === 0) {
          const position = Cesium.Cartesian3.fromDegrees(
            coord[0],
            coord[1],
            coord[2] + this.COURSE_HEIGHT_FROM_GROUND
          )

          this.kmDS.entities.add({
            position: position,
            billboard: {
              image: this.stylesIcons.pk[`PK_${m / 1000}`],
              // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
              show: true,
              width: 30,
              height: 87,
              horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
              alignedAxis: Cesium.Cartesian3.ZERO,
              eyeOffset: new Cesium.Cartesian3(0, 0, -50)
            }
          })
        }
      })
    }
  }

  /**
   * Add start and end
   * @param id The id of the course
   */
  private addStartEndPoints (id: string): void {
    const courseElevProfile: CourseElevationProfile | undefined = this.coursesEelevationProfile.find((cds: CourseElevationProfile) => cds.id === id)

    if (courseElevProfile?.elevationProfile?.coordinates?.length) {
      const startCoord: number[] = courseElevProfile.elevationProfile?.coordinates[0]
      const endCoord: number[] = courseElevProfile.elevationProfile?.coordinates[courseElevProfile.elevationProfile?.coordinates.length - 1]

      const position1 = Cesium.Cartesian3.fromDegrees(
        startCoord[0],
        startCoord[1],
        startCoord[2] + this.COURSE_HEIGHT_FROM_GROUND
      )

      const position2 = Cesium.Cartesian3.fromDegrees(
        endCoord[0],
        endCoord[1],
        endCoord[2] + this.COURSE_HEIGHT_FROM_GROUND
      )

      this.kmDS.entities.add({
        position: position1,
        billboard: {
          image: this.stylesIcons.startFinish.start,
          // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          show: true,
          width: 50,
          height: 90,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          alignedAxis: Cesium.Cartesian3.ZERO
        }
      })

      this.kmDS.entities.add({
        position: position2,
        billboard: {
          image: this.stylesIcons.startFinish.finish,
          // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
          show: true,
          width: 50,
          height: 90,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          alignedAxis: Cesium.Cartesian3.ZERO
        }
      })
    }
  }

  /**
   * Get visible course entity
   * @returns The visible course entity
   */
  private getVisibleCourseEntity (): Cesium.Entity | undefined {
    if (this.coursesDS) {
      return this.coursesDS.entities.values.find((e: Cesium.Entity) => e.isShowing)
    }

    return undefined
  }

  /**
   * Zoom to a course
   * @param panToTop Pan to top or not
   */
  private async zoomToCourse (panToTop = false): Promise<void> {
    try {
      if (this.coursesDS) {
        const entity: Cesium.Entity | undefined = this.getVisibleCourseEntity()

        if (entity) {
          if (this.mapMode === 2) {
            await this.viewer.flyTo(entity, { duration: 0.5 })
          }

          if (this.currentCourseId !== this.config.data.coursesData.initialCourseIdZoomIn) {
            this.handleCoursePolyline(entity)
          }

          this.handleDefaultView(entity)

          if (this.mapMode === 2 && panToTop) {
            this.zoomToCourse2D(entity, panToTop)
          } else if (this.mapMode === 3) {
            this.zoomToCourse3D(entity)
          }
        }
      }
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * Handle zoom to course if 2D Mode
   * @param entity The course entity
   * @param panToTop Pan to top or not
   */
  private async zoomToCourse2D (entity: Cesium.Entity, panToTop = false): Promise<void> {
    if (panToTop) {
      const rectangle: Cesium.Rectangle | undefined = new Cesium.Rectangle()
      this.viewer.camera.computeViewRectangle(this.viewer.scene.globe.ellipsoid, rectangle)
      const e: Cesium.Rectangle = this.expandRectangleByPixels(rectangle, 10, this.currentCourseId === this.config.data.coursesData.initialCourseIdZoomIn ? 0 : 250)
      this.viewer.camera.setView({ destination: e })
    } else {
      this.viewer.flyTo(entity)
    }
  }

  /**
   * Handle zoom to course if 3D Mode
   * @param entity The course entity
   */
  private async zoomToCourse3D (entity: Cesium.Entity): Promise<void> {
    const bookmark: Bookmark = this.config.bookmarks[this.currentCourseId]

    if (bookmark) {
      this.viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(bookmark.longitude, bookmark.latitude, bookmark.height),
        orientation: {
          heading: Cesium.Math.toRadians(bookmark.heading),
          pitch: Cesium.Math.toRadians(bookmark.pitch),
          roll: Cesium.Math.toRadians(bookmark.roll)
        }
      })
    } else {
      this.viewer.flyTo(entity)
    }
  }

  /**
   * Handle course polyline
   * @param entity The entity
   */
  private handleCoursePolyline (entity: Cesium.Entity): void {
    if (entity?.polyline) {
      if (this.mapMode === 2) {
        if (!this.courseEntity) {
          this.courseEntity = new Cesium.Entity({
            polyline: {
              positions: entity.polyline.positions,
              width: this.mapMode === 2 ? this.config.data.coursesData.style.width2D : this.config.data.coursesData.style.width3D,
              material: Cesium.Color.fromCssColorString(this.config.data.coursesData.style.color),
              clampToGround: false
            }
          })

          this.viewer.entities.add(this.courseEntity)
        }

        (this.courseEntity.polyline as any).positions = entity.polyline.positions
      } else {
        entity.polyline.width = this.config.data.coursesData.style.width3D as any
        entity.polyline.material = new Cesium.PolylineOutlineMaterialProperty({
          color: Cesium.Color.fromCssColorString(this.config.data.coursesData.style.color) as any,
          outlineWidth: this.config.data.coursesData.style.outlineWidth,
          outlineColor: Cesium.Color.fromCssColorString(this.config.data.coursesData.style.outlineColor)
        })
      }
    }
  }

  /**
   * Handle default entity
   * @param entity The entity
   */
  private handleDefaultView (entity: Cesium.Entity): void {
    if (entity.polyline && this.currentCourseId === this.config.data.coursesData.initialCourseIdZoomIn) {
      if (!this.courseEntity) {
        this.courseEntity = new Cesium.Entity({
          polyline: {
            positions: entity.polyline.positions,
            width: this.mapMode === 2 ? this.config.data.coursesData.style.width2D : this.config.data.coursesData.style.width3D,
            material: new Cesium.PolylineDashMaterialProperty({
              color: Cesium.Color.fromCssColorString(this.config.data.coursesData.style.color),
              gapColor: Cesium.Color.fromCssColorString('#fff'),
              dashLength: 25
            }),
            clampToGround: this.mapMode === 2
            // classificationType: Cesium.ClassificationType.TERRAIN as any
          }
        })

        this.viewer.entities.add(this.courseEntity)
      }

      (this.courseEntity.polyline as any).positions = entity.polyline.positions

      const courseInfo: CoursesInfos | undefined = this.coursesInfos.find((ci: CoursesInfos) => ci.properties.id === this.config.data.coursesData.initialCourseIdZoomIn)

      if (courseInfo?.properties?.rdvLatitude && courseInfo?.properties.rdvLongitude) {
        if (this.currentCourseId === this.config.data.coursesData.initialCourseIdZoomIn) {
          this.goToDefaultViewLocation(courseInfo?.properties.rdvLatitude, courseInfo?.properties.rdvLongitude, courseInfo?.properties.lieuRdv, false, false)
        } else {
          this.goToTrainingLocation(courseInfo?.properties.rdvLatitude, courseInfo?.properties.rdvLongitude, courseInfo?.properties.lieuRdv, false, false)
        }
      }
    }
  }

  private expandRectangleByPixels (rectange: Cesium.Rectangle, pixelOffsetW: number, pixelOffsetH: number): Cesium.Rectangle {
    const west = rectange.west
    const south = rectange.south
    const east = rectange.east
    const north = rectange.north

    const cartographicWest = Cesium.Cartographic.fromDegrees(Cesium.Math.toDegrees(west), Cesium.Math.toDegrees(south))
    const cartographicEast = Cesium.Cartographic.fromDegrees(Cesium.Math.toDegrees(east), Cesium.Math.toDegrees(north))

    const currentWidth = cartographicEast.longitude - cartographicWest.longitude
    const currentHeight = cartographicEast.latitude - cartographicWest.latitude

    const newWidth = currentWidth + (2 * pixelOffsetW)
    const newHeight = currentHeight + (2 * pixelOffsetH)

    const newWest = cartographicWest.longitude // - (pixelOffsetW * (currentWidth / newWidth))
    const newSouth = cartographicWest.latitude - (pixelOffsetH * (currentHeight / newHeight))
    const newEast = cartographicEast.longitude // + (pixelOffsetW * (currentWidth / newWidth))
    const newNorth = cartographicEast.latitude // + (pixelOffsetH * (currentHeight / newHeight))

    // Return the new rectangle coordinates
    return Cesium.Rectangle.fromRadians(newWest, newSouth, newEast, newNorth)
  }

  /**
   * Get camera looking at
   * @returns The lat and long of camera lookAt
   */
  private cameraLookingAt (): { lat: number, long: number, height: number } | null {
    const camera = this.viewer.camera
    const canvas = this.viewer.scene.canvas

    const ray = camera.getPickRay(new Cesium.Cartesian2(
      Math.round(canvas.clientWidth / 2),
      Math.round(canvas.clientHeight / 2)
    ))

    if (ray) {
      const position = this.viewer.scene.globe.pick(ray, this.viewer.scene)

      if (position && Cesium.defined(position)) {
        const cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position)
        const height = cartographic.height
        // const range = Cesium.Cartesian3.distance(position, camera.position)
        return { lat: Cesium.Math.toDegrees(cartographic.latitude), long: Cesium.Math.toDegrees(cartographic.longitude), height: height }
      }
    }

    return null
  }

  /**
   * Hide terrain
   */
  private hideTerrain (): void {
    const imageryLayers = this.viewer.imageryLayers
    if (imageryLayers.length) {
      const terrainLayer = imageryLayers.get(0)
      imageryLayers.remove(terrainLayer)
      this.terrainProvider = undefined
    }

    setTimeout(() => {
      this.viewer.scene.setTerrain(Cesium.Terrain.fromWorldTerrain())
    }, 1000)
  }

  /**
   * Refresh courses state
   */
  public refreshCourses (): void {
    this.removeCoursesFromMap()
    this.currentCourseId = ''
    this.stopFlyAlongCourse()

    if (this.courseEntity) {
      this.viewer.entities.remove(this.courseEntity)
      this.courseEntity = null as any
    }

    if (this.flyEntity) {
      this.viewer.entities.remove(this.flyEntity)
      this.flyEntity = null as any
    }

    if (this.trainingLocationEntity) {
      this.viewer.entities.remove(this.trainingLocationEntity)
      this.trainingLocationEntity = null as any
    }

    if (this.hoverEntity) {
      this.viewer.entities.remove(this.hoverEntity)
      this.hoverEntity = null as any
    }

    EventsManager.getInstance().eventEmitter.emit('setPlayProgress' as any, { value: 0 })
  }

  /**
   * Open course
   * @param id The id of the course
   * @param showProfile Show profile or not
   */
  public async openCourse (id: string, showProfile = true): Promise<void> {
    try {
      // Remove remaining courses from the map
      this.refreshCourses()

      this.currentCourseId = id

      const courseElevProfile: CourseElevationProfile | undefined = this.coursesEelevationProfile.find((i: CourseElevationProfile) => i.id === id)
      let elevProfileResult: ElevProfileResult | undefined
      const courseFeat: GeoJSONFeature | undefined = this.courses.find((f: GeoJSONFeature) => f.properties[this.config.data.coursesData.courses.fields.id] === id)
      const courseInfo: CoursesInfos | undefined = this.coursesInfos.find((ci: CoursesInfos) => ci.properties.id === id)
      if (this.coursesDS && courseFeat) {
        if (courseElevProfile?.elevationProfile) {
          elevProfileResult = courseElevProfile.elevationProfile
        } else {
          elevProfileResult = QueryManager.computeElevationProfile(courseFeat.coordinatesM ? courseFeat.coordinatesM : [], courseInfo)

          if (elevProfileResult) {
            this.coursesEelevationProfile.push({ id: id, elevationProfile: elevProfileResult! })
          }
        }

        courseFeat.geometry.coordinates = elevProfileResult!.coordinates

        // Filter courses
        this.filterCoursesDs(id)

        // Set global state
        if (showProfile) {
          store.commit('setCurrentCourseId', id)
          this.addStartEndPoints(id)

          // Add km point
          this.addKMPoints(id)
        }

        // Fly to datasource
        await this.zoomToCourse((showProfile || this.mapMode === 2) && !ToolsManager.getInstance().isMobileView /* showProfile */)
      }
    } catch (err) {
      console.error(err)
      return Promise.reject(err)
    }
  }

  /**
   * Get MappingManager instance
   */
  public static getInstance (): MappingManager {
    if (!MappingManager.instance) {
      MappingManager.instance = new MappingManager()
    }

    return MappingManager.instance
  }

  /**
   * Create fly / hover point
   * @returns A point
   */
  private createFlyHoverPoint (): any {
    return {
      pixelSize: 15,
      color: Cesium.Color.fromCssColorString(this.config.data.coursesData.style.color),
      outlineColor: Cesium.Color.WHITE,
      outlineWidth: 3 /* ,
      heightReference: Cesium.HeightReference.CLAMP_TO_GROUND */
    }
  }

  /**
   * Fly along course
   * @param id The id of the course
   */
  public flyAlongCourse (id: string): void {
    if (this.hoverEntity) {
      this.viewer.entities.remove(this.hoverEntity)
      this.hoverEntity = null as any
    }

    // Orbit this point
    if (this.orbitEvent) {
      this.viewer.clock.onTick.removeEventListener(this.orbitEvent)
    }

    if (!this.flyEntity) {
      // Set bounds of our simulation time
      const start = Cesium.JulianDate.fromDate(new Date())
      if (!this.sampledPositionProperty) {
        this.sampledPositionProperty = this.computeCourseFlight(id, start)
      }
      const stop = Cesium.JulianDate.addSeconds(
        start,
        this.getCourseDuration(id),
        new Cesium.JulianDate()
      )

      // Make sure viewer is at the desired time.
      this.viewer.clockViewModel.shouldAnimate = true
      this.viewer.clockViewModel.clock.startTime = start.clone()
      this.viewer.clockViewModel.clock.stopTime = this.endTime.clone()
      this.viewer.clockViewModel.clock.currentTime = start.clone()
      // this.viewer.clockViewModel.clock.clockRange = Cesium.ClockRange.CLAMPED
      this.viewer.clockViewModel.clock.multiplier = 1
      this.viewer.clockViewModel.clockStep = 1

      // Set timeline to simulation bounds
      // clockVM.timeline.zoomTo(start, stop)

      // this.velocityVectorProperty = new Cesium.VelocityVectorProperty(this.sampledPositionProperty, false)

      this.flyEntity = new Cesium.Entity({
        // Set the entity availability to the same interval as the simulation time.
        availability: new Cesium.TimeIntervalCollection([
          new Cesium.TimeInterval({
            start: start,
            stop: stop
          })
        ]),

        // Use our computed positions
        position: this.sampledPositionProperty,

        // Automatically compute orientation based on position movement.
        // orientation: new Cesium.VelocityOrientationProperty(this.sampledPositionProperty),
        point: this.createFlyHoverPoint()
      })

      this.viewer.entities.add(this.flyEntity)
      this.viewer.trackedEntity = this.flyEntity
      this.handleZoomOnFly()
    } else if (this.viewer.clockViewModel) {
      this.viewer.clockViewModel.shouldAnimate = true
      this.viewer.clockViewModel.canAnimate = true

      if (this.dragTime) {
        this.viewer.clockViewModel.clock.currentTime = this.dragTime
        this.dragTime = null as any
      }

      // this.viewer.trackedEntity = this.flyEntity
      // this.handleZoomOnFly()
    }
  }

  private handleZoomOnFly (): void {
    setTimeout(() => {
      this.viewer.camera.zoomOut(300)
    }, 200)
  }

  /**
   * Pause flying along course
   */
  public pauseFlyingAlongCourse (): void {
    if (this.viewer.clockViewModel) {
      this.viewer.clockViewModel.shouldAnimate = false
      this.viewer.clockViewModel.canAnimate = false
      // this.viewer.trackedEntity = undefined
    }
  }

  /**
   * Stop fly course
   */
  public stopFlyAlongCourse (): void {
    EventsManager.getInstance().eventEmitter.emit('stopPlay' as any)
    this.pauseFlyingAlongCourse()
    this.sampledPositionProperty = null as any
    this.viewer.trackedEntity = undefined
    EventsManager.getInstance().eventEmitter.emit('setPlayProgress' as any, { value: 0 })

    if (this.flyEntity) {
      this.viewer.entities.remove(this.flyEntity)
      this.flyEntity = null as any
    }

    if (this.hoverEntity) {
      this.viewer.entities.remove(this.hoverEntity)
      this.hoverEntity = null as any
    }
  }

  /**
   * Get travel times
   */
  public getTravelTimes (): any[] {
    return this.travelTimes
  }

  /**
   * Get elevation profile by course id
   * @param id The id of the course
   * @returns The elevation profile
   */
  public getElevationProfile (id: string): ElevProfileResult | undefined {
    const courseElevProfile: CourseElevationProfile | undefined = this.coursesEelevationProfile.find((i: CourseElevationProfile) => i.id === id)
    return courseElevProfile!.elevationProfile
  }

  /**
   * Switch map mode
   * @param mode The mode (2D or 3D)
   */
  public async switchMapMode (mode: number): Promise<void> {
    // Disable left rotation
    this.setMouseLeftRotation(false)

    // Show street names
    if (this.streetsDS) {
      this.streetsDS.show = mode === 3
    }

    // Switch mode event
    EventsManager.getInstance().eventEmitter.emit('switchMapMode' as any, mode)
    // Pause course
    if (this.flyEntity) {
      this.stopFlyAlongCourse()
    }

    if (this.mapMode !== mode) {
      this.mapMode = mode
      this.viewer.scene.mode = this.mapMode === 3 ? Cesium.SceneMode.SCENE3D : Cesium.SceneMode.SCENE2D

      // Hide 3D buildings if 2D mode
      if (this.mapMode === 2 && !this.terrainProvider) {
        await this.loadOSM3DTerrain()
      }

      if (this.terrainProvider && mode === 3) {
        this.hideTerrain()
      }

      if (mode === 3 && !this.buildingsProvider) {
        await this.load3DData()
      }
      if (this.buildingsProvider) {
        this.buildingsProvider.show = this.mapMode === 3
      }

      if (this.mapMode === 2) {
        this.viewer.scene.mode = Cesium.SceneMode.SCENE2D
      }

      this.setCoursesPolylinesWidth()
      setTimeout(async (): Promise<void> => {
        this.currentCourseId = !this.currentCourseId ? this.config.data.coursesData.initialCourseIdZoomIn : this.currentCourseId

        if (this.currentCourseId === this.config.data.coursesData.initialCourseIdZoomIn) {
          MappingManager.getInstance().flyToHomePosition()
        } else {
          this.zoomToCourse(this.currentCourseId !== this.config.data.coursesData.initialCourseIdZoomIn)
        }
      }, 100)
    }
  }

  /**
   * Switch map mode
   * @param mode The mode (2D or 3D)
   */
  public async switchMapModeOld (mode: number): Promise<void> {
    // Disable left rotation
    this.setMouseLeftRotation(false)

    // Swithc mode event
    EventsManager.getInstance().eventEmitter.emit('switchMapMode' as any, mode)
    // Pause course
    if (this.flyEntity) {
      EventsManager.getInstance().eventEmitter.emit('stopPlay' as any)
    }

    if (this.mapMode !== mode) {
      this.mapMode = mode

      // Cesium.Math.setRandomNumberSeed(3)

      this.setCameraState()

      if (this.cameraSate.long && this.cameraSate.lat && this.cameraSate.distance) {
        const angle = Cesium.Math.toDegrees(this.viewer.camera.pitch)
        const diff = mode === 2 ? -90 - angle : 45
        // this.viewer.camera.rotateDown(Cesium.Math.toRadians(diff))
        if (this.mapMode === 3) {
          this.viewer.scene.mode = Cesium.SceneMode.SCENE3D
        }
        this.tilt(diff, () => {
          this.setCameraRotationEnabled(mode === 3)

          setTimeout(async (): Promise<void> => {
            // Hide 3D buildings if 2D mode
            if (this.mapMode === 2 && !this.terrainProvider) {
              await this.loadOSM3DTerrain()
            }

            if (this.terrainProvider && mode === 3) {
              this.hideTerrain()
            }

            if (mode === 3 && !this.buildingsProvider) {
              await this.load3DData()
            }
            if (this.buildingsProvider) {
              this.buildingsProvider.show = this.mapMode === 3
            }

            if (this.mapMode === 2) {
              this.viewer.scene.mode = Cesium.SceneMode.SCENE2D
            }

            this.setCoursesPolylinesWidth()
          }, 100)
        })
      }
    }
  }

  /**
   * Switch map mode
   * @param mode The mode (2D or 3D)
   */
  public async switchMapModeNew (mode: number): Promise<void> {
    // Disable left rotation
    this.setMouseLeftRotation(false)

    // Swithc mode event
    EventsManager.getInstance().eventEmitter.emit('switchMapMode' as any, mode)
    // Pause course
    if (this.flyEntity) {
      EventsManager.getInstance().eventEmitter.emit('stopPlay' as any)
    }

    if (this.mapMode !== mode) {
      this.mapMode = mode

      // Cesium.Math.setRandomNumberSeed(3)

      this.setCameraState()

      if (this.cameraSate.long && this.cameraSate.lat && this.cameraSate.distance) {
        const angle = Cesium.Math.toDegrees(this.viewer.camera.pitch)
        const diff = -90 - angle
        // this.viewer.camera.rotateDown(Cesium.Math.toRadians(diff))
        if (this.mapMode === 3) {
          this.viewer.scene.mode = Cesium.SceneMode.SCENE3D
        }
        this.rotateCameraVertically(diff)
        this.setCameraRotationEnabled(mode === 3)
        setTimeout(async (): Promise<void> => {
          // Hide 3D buildings if 2D mode
          if (this.mapMode === 2 && !this.terrainProvider) {
            await this.loadOSM3DTerrain()
          }

          if (this.terrainProvider && mode === 3) {
            this.hideTerrain()
          }

          if (mode === 3 && !this.buildingsProvider) {
            await this.load3DData()
          }
          if (this.buildingsProvider) {
            this.buildingsProvider.show = this.mapMode === 3
          }

          if (this.mapMode === 2) {
            this.viewer.scene.mode = Cesium.SceneMode.SCENE2D
          }

          this.setCoursesPolylinesWidth()
        }, 100)
      }
    }
  }

  private rotateCameraVertically (angleDegrees: number): void {
    const center = this.viewer.camera.pickEllipsoid(new Cesium.Cartesian2(this.viewer.canvas.clientWidth / 2, this.viewer.canvas.clientHeight / 2))

    if (center) {
      const heading = this.viewer.camera.heading
      const pitch = Cesium.Math.toRadians(angleDegrees)
      this.viewer.camera.lookAt(center, new Cesium.HeadingPitchRange(heading, pitch, this.getHeight()))
    }
  }

  private getHeight (): number {
    const centerPoint = this.viewer.camera.pickEllipsoid(new Cesium.Cartesian2(this.viewer.canvas.clientWidth / 2, this.viewer.canvas.clientHeight / 2))
    const cameraPosition = this.viewer.camera.positionWC
    return centerPoint ? Cesium.Cartesian3.distance(cameraPosition, centerPoint) : 1000
  }

  /**
   * Orbit around the default view
   * @param center The orbit center
   */
  private orbitDefaultView (lat: number, long: number): void {
    const center = Cesium.Cartesian3.fromDegrees(long, lat, 400)
    const transform = Cesium.Transforms.eastNorthUpToFixedFrame(center, this.viewer.scene.globe.ellipsoid)

    // Orbit this point
    if (this.orbitEvent) {
      this.viewer.clock.onTick.removeEventListener(this.orbitEvent)
    }

    this.orbitEvent = (clock: any) => {
      if (this.currentCourseId === this.config.data.coursesData.initialCourseIdZoomIn && this.isOrbiting) {
        this.viewer.scene.camera.lookAtTransform(transform)
        this.viewer.scene.camera.rotateRight(this.config.cesiumOptions.orbitRotateAmount)
      } else {
        this.viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY)
      }
    }

    this.viewer.clock.onTick.addEventListener(this.orbitEvent)
  }

  /**
   * Zoom in
   */
  public zoomIn (): void {
    if (this.mapMode === 2) {
      this.viewer.camera.zoomIn()
      return
    }

    // Limit camera zoom in 3D
    const rectangle = this.viewer.camera.computeViewRectangle(Cesium.Ellipsoid.WGS84)
    if (rectangle && rectangle?.width > 0.0005) {
      this.viewer.camera.zoomIn()
    }
  }

  /**
   * Zoom out
   */
  public zoomOut (): void {
    this.viewer.camera.zoomOut()
  }

  /**
   * Fly to home position
   */
  public flyToHomePosition (): void {
    if (!this.config.data.coursesData.initialCourseIdZoomIn) {
      this.zoomToConfigView()
    } else {
      this.openCourse(this.config.data.coursesData.initialCourseIdZoomIn, false)
    }
  }

  /**
   * Enable / disable mouse left rotation
   * @param enabled Is the left rotation enabled
   */
  public setMouseLeftRotation (enabled: boolean): void {
    this.viewer.scene.screenSpaceCameraController.tiltEventTypes = enabled ? Cesium.CameraEventType.LEFT_DRAG : Cesium.CameraEventType.MIDDLE_DRAG
    this.setViewerCursor(enabled)
  }

  /**
   * Handle hover course line
   * @param coords The point coords
   */
  public handleHoverCourseLine (coords: number[]): void {
    const position = Cesium.Cartesian3.fromDegrees(coords[0], coords[1], coords[2] + this.COURSE_HEIGHT_FROM_GROUND)

    if (!this.hoverEntity) {
      this.hoverEntity = new Cesium.Entity({
        point: this.createFlyHoverPoint()
      })

      this.viewer.entities.add(this.hoverEntity)
    }

    this.hoverEntity.position = position as any
  }

  /**
   * Fly to latitude / longitude
   * @param lat The location latitude
   * @param long The location longitude
   */
  public flyToLatLong (lat: number, long: number): void {
    this.viewer.scene.camera.setView({
      destination: Cesium.Cartesian3.fromDegrees(long,
        lat,
        this.config.cesiumOptions.view3D.camera.destination.z),
      orientation: {
        heading: Cesium.Math.toRadians(this.config.cesiumOptions.view3D.camera.orientation.heading),
        pitch: Cesium.Math.toRadians(this.config.cesiumOptions.view3D.camera.orientation.pitch)
      }
    })
  }

  /**
   * Go to training location
   * @param lat The location latitude
   * @param long The location longitude
   * @param locationName The location name
   * @param showSymbol Show or not the location symbol
   * @param zoomTo Zoom or not to the location
   */
  public async goToTrainingLocation (lat: number, long: number, locationName: string, showSymbol = true, zoomTo = true): Promise<void> {
    if (this.trainingLocationEntity) {
      this.viewer.entities.remove(this.trainingLocationEntity)
      this.trainingLocationEntity = null as any
    }

    const center = Cesium.Cartesian3.fromDegrees(long, lat, 520)

    let labelText = locationName

    if (this.currentCourseId === this.config.data.coursesData.initialCourseIdZoomIn) {
      labelText = this.mapMode === 2 ? this.DEFAULT_VIEW_LABEL_2D : this.DEFAULT_VIEW_LABEL_3D
    }

    this.trainingLocationEntity = this.viewer.entities.add({
      position: center,
      billboard: {
        image: this.stylesIcons.icons.pushpin,
        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        show: showSymbol,
        width: 25,
        height: 31,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        alignedAxis: Cesium.Cartesian3.ZERO
      },
      label: {
        // heightReference: this.mapMode === 3 ? Cesium.HeightReference.RELATIVE_TO_TERRAIN : Cesium.HeightReference.NONE,
        fillColor: Cesium.Color.fromCssColorString('#FFFFFF'),
        font: `500 ${ToolsManager.getInstance().isMobileView ? '12' : '15'}pt helvetica`,
        outlineColor: Cesium.Color.fromCssColorString('#000'),
        outlineWidth: 1,
        style: Cesium.LabelStyle.FILL,
        scale: 1,
        text: labelText,
        backgroundColor: Cesium.Color.fromCssColorString('#7bb02e90'),
        showBackground: true,
        backgroundPadding: new Cesium.Cartesian2(5, 5),
        pixelOffset: new Cesium.Cartesian2(0, 12)
      }
    })

    if (zoomTo) {
      if (this.mapMode === 2) {
        this.viewer.scene.camera.setView({
          destination: Cesium.Cartesian3.fromDegrees(long, lat, this.config.cesiumOptions.view3D.camera.destination.z)
        })
      } else {
        await this.viewer.zoomTo(this.trainingLocationEntity,
          new Cesium.HeadingPitchRange(
            this.viewer.camera.heading,
            this.viewer.camera.pitch,
            1500
          )
        )
      }
    }
  }

  /**
   * Go to default view location
   * @param lat The location latitude
   * @param long The location longitude
   * @param locationName The location name
   * @param showSymbol Show or not the location symbol
   * @param zoomTo Zoom or not to the location
   */
  public async goToDefaultViewLocation (lat: number, long: number, locationName: string, showSymbol = true, zoomTo = true): Promise<void> {
    if (this.trainingLocationEntity) {
      this.viewer.entities.remove(this.trainingLocationEntity)
      this.trainingLocationEntity = null as any
    }

    const center = Cesium.Cartesian3.fromDegrees(long, lat, 520)
    const img = await html2canvas(document.getElementById(this.mapMode === 3 ? 'perimtreManifestationSurvol' : 'perimtreManifestation') as any, { backgroundColor: null })

    this.trainingLocationEntity = this.viewer.entities.add({
      position: center,
      id: this.currentCourseId === this.config.data.coursesData.initialCourseIdZoomIn ? 'DEFAULT_VIEW' : 'OTHER',
      billboard: {
        image: img.toDataURL(),
        // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
        show: true,
        width: ToolsManager.getInstance().isMobileView ? 250 : 400,
        height: ToolsManager.getInstance().isMobileView ? 25 : 40,
        horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        alignedAxis: Cesium.Cartesian3.ZERO,
        eyeOffset: new Cesium.Cartesian3(0, 0, -5)
      }
    })

    if (zoomTo) {
      if (this.mapMode === 2) {
        this.viewer.scene.camera.setView({
          destination: Cesium.Cartesian3.fromDegrees(long, lat, this.config.cesiumOptions.view3D.camera.destination.z)
        })
      } else {
        await this.viewer.zoomTo(this.trainingLocationEntity,
          new Cesium.HeadingPitchRange(
            this.viewer.camera.heading,
            this.viewer.camera.pitch,
            1500
          )
        )
      }
    }

    if (this.mapMode === 3) {
      this.orbitDefaultView(lat, long)
    }
  }

  /**
   * Go to training location
   * @param lat The location latitude
   * @param long The location longitude
   * @param zoomTo Zoom or not to the location
   */
  public locateMe (lat: number, long: number, zoomTo = false): void {
    const position: Cesium.Cartesian3 = Cesium.Cartesian3.fromDegrees(long, lat, 1)

    if (!this.locateMeEntity) {
      this.locateMeEntity = this.viewer.entities.add({
        position: position,
        billboard: {
          image: this.stylesIcons.icons.pushpin,
          heightReference: Cesium.HeightReference.NONE,
          width: 25,
          height: 31,
          show: true,
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          alignedAxis: Cesium.Cartesian3.ZERO
        }
      })
    } else {
      this.locateMeEntity.position = position as any
      this.locateMeEntity.show = true
    }

    if (zoomTo) {
      this.viewer.scene.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(long, lat, this.config.cesiumOptions.view3D.camera.destination.z)
      })
    }
  }

  /**
   * Remove locate me entity
   */
  public removeLocateMe (): void {
    if (this.locateMeEntity) {
      this.locateMeEntity.show = false
    }
  }

  /**
   * Get the current map mode
   * @returns The current map mode (2 | 3)
   */
  public getMapMode (): number {
    return this.mapMode
  }

  public logCameraPos (): void {
    const save: any = {
      heading: Cesium.Math.toDegrees(this.viewer.camera.heading),
      pitch: Cesium.Math.toDegrees(this.viewer.camera.pitch),
      roll: Cesium.Math.toDegrees(this.viewer.camera.roll),
      height: this.viewer.camera.positionCartographic.height,
      latitude: Cesium.Math.toDegrees(this.viewer.camera.positionCartographic.latitude),
      longitude: Cesium.Math.toDegrees(this.viewer.camera.positionCartographic.longitude)
    }

    console.log(`
    "latitude": ${save.latitude},
    "longitude": ${save.longitude},
    "height": ${save.height},
    "heading": ${save.heading},
    "pitch": ${save.pitch},
    "roll": ${save.roll}
    `)
  }

  private rotateAroundAxis (angle: number, axis: any, transform: any, callback: any): void {
    const clamp = Cesium.Math.clamp
    const defaultValue = Cesium.defaultValue
    const duration = 100

    // const easing = defaultValue(options.easing, ol_easing_js__WEBPACK_IMPORTED_MODULE_0__["linear"])
    let lastProgress = 0
    const oldTransform = new Cesium.Matrix4()
    const start = Date.now()

    const step = () => {
      const timestamp = Date.now()
      const timeDifference = timestamp - start
      const progress = clamp(timeDifference / duration, 0, 1)
      console.assert(progress >= lastProgress)
      this.viewer.camera.transform.clone(oldTransform)
      const stepAngle = (progress - lastProgress) * angle
      lastProgress = progress
      this.viewer.camera.lookAtTransform(transform)
      this.viewer.camera.rotate(axis, stepAngle)
      this.viewer.camera.lookAtTransform(oldTransform)

      if (progress < 1) {
        window.requestAnimationFrame(step)
      } else if (callback) {
        callback()
      }
    }

    window.requestAnimationFrame(step)
  }

  private pickBottomPoint () {
    const canvas = this.viewer.scene.canvas
    const bottom = new Cesium.Cartesian2(canvas.clientWidth / 2, canvas.clientHeight)
    return this.pickOnTerrainOrEllipsoid(bottom)
  }

  private pickOnTerrainOrEllipsoid (pixel: Cesium.Cartesian2) {
    const ray = this.viewer.scene.camera.getPickRay(pixel)

    if (ray) {
      // const target = this.viewer.scene.globe.pick(ray, this.viewer.scene)
      const ellipsoid = this.viewer.scene.globe.ellipsoid
      const result = this.viewer.camera.pickEllipsoid(pixel, ellipsoid)
      if (result) {
        if (this.centerEntity) {
          this.viewer.entities.remove(this.centerEntity)
        }
      }

      return result
    }
  }

  private async rotateAroundBottomCenter (angle: number, callback: any): Promise<void> {
    const camera = this.viewer.scene.camera
    const pivot = this.pickBottomPoint()

    if (!pivot) {
      return
    }

    const transform = Cesium.Matrix4.fromTranslation(pivot)
    const axis = camera.right
    this.rotateAroundAxis(-angle, axis, transform, callback)
  }

  private tilt (angle: number, callback: any) {
    const a = Cesium.Math.toRadians(angle)
    const b = this.pickBottomPoint()
    if (b) {
      const c = Cesium.Matrix4.fromTranslation(b)
      this.rotateAroundAxis(-a, this.viewer.camera.right, c, callback)
    }
  }

  public test () {
    // this.pickBottomPoint()
    const rectangle = this.viewer.camera.computeViewRectangle(Cesium.Ellipsoid.WGS84)
    this.viewer.entities.add({
      rectangle: { coordinates: rectangle, material: Cesium.Color.GREEN.withAlpha(0.5) }
    })
  }
}
