
import { defineComponent, PropType } from 'vue'
import { CoursesInfos } from '../../../models/courses'
import MappingManager from '@/shared/mappingManager'
import ToolsManager from '@/shared/toolsManager'
import EventsManager from '@/shared/eventsManager'

export default defineComponent({
  name: 'RaceItem',
  props: {
    courseInfos: Object as PropType<CoursesInfos>
  },
  data () {
    return {
      courseInfos_: this.courseInfos,
      isMobileView: ToolsManager.getInstance().isMobileView
    }
  },
  methods: {
    openCourse: function () {
      if (this.courseInfos_) {
        MappingManager.getInstance().openCourse(this.courseInfos_?.properties.id)
        this.$emit('openCourseItem', { course: this.courseInfos_?.properties.course, distance: this.courseInfos_?.properties.distance })
      }
    },
    zoomToTrainingLocation: function (event: any) {
      event.preventDefault()
      event.stopPropagation()

      if (this.courseInfos_ && this.courseInfos_?.properties && this.courseInfos_?.properties.rdvLatitude && this.courseInfos_?.properties.rdvLongitude) {
        MappingManager.getInstance().goToTrainingLocation(this.courseInfos_?.properties.rdvLatitude, this.courseInfos_?.properties.rdvLongitude, this.courseInfos_?.properties.lieuRdv)
        EventsManager.getInstance().eventEmitter.emit('collapseSearch' as any)
      }
    }
  }
})
