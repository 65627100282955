import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppHeader = _resolveComponent("AppHeader")!
  const _component_CesiumViewer = _resolveComponent("CesiumViewer")!
  const _component_AppLogo = _resolveComponent("AppLogo")!
  const _component_Races = _resolveComponent("Races")!
  const _component_DevelopedBy = _resolveComponent("DevelopedBy")!
  const _component_ElevationProfile = _resolveComponent("ElevationProfile")!
  const _component_LoadingProgress = _resolveComponent("LoadingProgress")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.configLoaded)
      ? (_openBlock(), _createBlock(_component_AppHeader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.configLoaded)
      ? (_openBlock(), _createBlock(_component_CesiumViewer, { key: 1 }))
      : _createCommentVNode("", true),
    _createVNode(_component_AppLogo),
    (_ctx.configLoaded && _ctx.dataLoaded)
      ? (_openBlock(), _createBlock(_component_Races, { key: 2 }))
      : _createCommentVNode("", true),
    _createVNode(_component_DevelopedBy),
    (_ctx.configLoaded && _ctx.dataLoaded)
      ? (_openBlock(), _createBlock(_component_ElevationProfile, { key: 3 }))
      : _createCommentVNode("", true),
    _createVNode(_component_LoadingProgress)
  ], 64))
}