
import EventsManager from '@/shared/eventsManager'
import MappingManager from '@/shared/mappingManager'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SideNav',
  props: {
    expanded: Boolean,
    width: Number,
    height: Number,
    position: String,
    icon: String
  },
  data () {
    return {
      expanded_: this.expanded,
      visible: false
    }
  },
  mounted: function () {
    EventsManager.getInstance().eventEmitter.on('openElevationProfile', this.openSideNav)
    EventsManager.getInstance().eventEmitter.on('closeElevationProfile', this.closeSideNav)
  },
  methods: {
    toggle: function () {
      this.expanded_ = !this.expanded_
      // MappingManager.getInstance().refreshCourses()
    },
    openSideNav: function () {
      setTimeout(() => {
        this.expanded_ = true
        this.visible = true
      }, 500)
    },
    closeSideNav: function () {
      setTimeout(() => {
        this.expanded_ = false
        this.visible = false
      }, 100)
    },
    getImage (): any {
      return this.icon ? require(`@/assets/icons/${this.icon}`) : ''
    }
  }
})
